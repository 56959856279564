import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BeatLoader from 'react-spinners/BeatLoader';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import history from '../utilities/History';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import FormFileInput from 'react-bootstrap/esm/FormFileInput';

export default function Apply(props) {
    const [job, setJob] = useState([])
    const [questions, setQuestions] = useState({ id: [], title: [], question: [], response_length: [], required: [], response: [] })
    const [validation, setValidation] = useState({ section: 0, firstName: false, middleName: false, lastName: false, proNoun: false, phoneNumber: false, preferredName: false });
    const [open, setOpen] = useState(false)
    const [phoneNumberCollapse, setPhoneNumberCollapse] = useState(false)
    const [loading, setLoading] = useState(true)
    const [formProgress, setFormProgress] = useState(0)
    const [firstName, setFirstName] = useState("")
    const [middleNames, setMiddleNames] = useState("")
    const [lastName, setLastName] = useState("")
    const [proNoun, setProNoun] = useState("")
    const [disability, setDisability] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [preferredName, setPreferredName] = useState("")
    const [saving, setSaving] = useState(0)
    const [saveQuestion, setSaveQuestion] = useState([])
    const [questionValidation, setQuestionValidation] = useState([])
    const [files, setFiles] = useState([])
    const [alreadyApplied, setAlreadyApplied] = useState(false)
    const [update, setUpdate] = useState(false)
    const [displayValidation, setDisplayValidation] = useState(0)
    function Validate() {
        switch (formProgress) {
            case 0:
                let tempValidation = validation
                tempValidation.section = 1
                if (firstName != null && firstName.length > 0) {
                    tempValidation.firstName = false
                } else {
                    tempValidation.firstName = true
                }
                if (lastName != null && lastName.length > 0) {
                    tempValidation.lastName = false
                } else {
                    tempValidation.lastName = true
                }
                if (firstName != null && firstName.length > 0 && lastName != null && lastName.length > 0) {
                    tempValidation.firstName = false
                    tempValidation.lastName = false
                    UpdateUser()
                }
                setValidation(tempValidation)
                setUpdate(false)
                break;
            case 1:
                let tempValidation2 = validation
                tempValidation2.section = 2
                if (proNoun != null && proNoun.length > 1) {
                    tempValidation2.proNoun = false
                } else {
                    tempValidation2.proNoun = true
                }
                if (preferredName != null && preferredName.length > 0) {
                    tempValidation2.preferredName = false
                } else {
                    tempValidation2.preferredName = true
                }
                if (proNoun != null && proNoun.length > 1 && preferredName != null && preferredName.length > 0) {
                    tempValidation2.proNoun = false
                    tempValidation2.preferredName = false
                    UpdateUser()
                }
                setValidation(tempValidation2)
                setUpdate(false)
                break;
            case 2:
                let tempValidation3 = validation
                tempValidation3.section = 3
                if (phoneNumber !== null) {
                    if (phoneNumber.match(/((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/)) {
                        tempValidation3.phoneNumber = false
                        UpdateUser()
                    } else {
                        tempValidation3.phoneNumber = true
                    }
                } else {
                    tempValidation3.phoneNumber = true
                }
                setValidation(tempValidation3)
                setUpdate(false)
                break;
            case 3:
                let questionValidationArray = []
                if (questions.id !== null) {
                    questions.id.forEach((Question, i) => {
                        if (questions.required[i] === 1) {
                            if (questions.response[i] === "") {
                                questionValidationArray = [...questionValidationArray, i]
                            } else if (questionValidationArray.indexOf(i) !== -1) {
                                questionValidationArray = [questionValidationArray.splice(questionValidationArray.indexOf(i), 1)]
                            }
                        }
                    })
                }
                if (questionValidationArray.length === 0) {
                    setSaving(0)
                    setFormProgress(formProgress + 1)
                } else {
                    setQuestionValidation(questionValidationArray)
                }
                break;
        }
        setDisplayValidation(displayValidation => displayValidation + 1)
    }
    function SubmitApplication() {
        setUpdate(true)
        let fd = new FormData();
        fd.append('jobId', props.match.params.id)
        if (files !== null) {
            files.forEach((file) => {
                fd.append('File[]', file);
            });
        }
        axios.post('/jobs/api/v1/application/submit.php', fd)
            .then((response) => {
                if (response.data.Message === "OK") {
                    setUpdate(false)
                    history.push('/applicant/applications')
                } else {
                    props.updateError(response.data.Message)
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
    }
    function UpdateUser() {
        setUpdate(true)
        axios.post('/jobs/api/v1/application/updateApplicant.php', {
            first_name: firstName,
            last_name: lastName,
            middle_names: middleNames,
            pronoun: proNoun,
            phone_number: phoneNumber,
            preferred_name: preferredName,
            disability: disability,
        }).then((response) => {
            if (response.data.Message === "OK") {
                setFormProgress(formProgress + 1)
                setUpdate(false)
            } else {
                setUpdate(false)
                props.updateError("Error Saving User Information")
            }
        }).catch(function (error) {
            setUpdate(false)
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    useEffect(() => {
        if (questions.id.length !== 0) {
            const delayDebounceFn = setTimeout(() => {
                if (saveQuestion.length !== 0) {
                    setSaving("Saving")
                    let loopCount = 0
                    let saveQuestionState = saveQuestion
                    saveQuestionState.forEach(i => {
                        axios.post('/jobs/api/v1/question/response.php', {
                            questionId: questions.id[i],
                            response: questions.response[i],
                            jobId: props.match.params.id
                        }).then((response) => {
                            if (response.data.Message === "OK") {
                                loopCount++
                                if (loopCount === saveQuestion.length) {
                                    setSaving("Responses Saved")
                                    saveQuestionState = []
                                    setSaveQuestion([])
                                }
                            } else {
                                props.updateError("Error Saving Question")
                                setSaving(0)
                            }
                        }).catch(function (error) {
                            if (error.response) {
                                props.updateError(error.response.data)
                            } else if (error.request) {
                                props.updateError(error.request)
                            } else {
                                props.updateError(error.message)
                            }
                        });
                    });
                }
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [questions])
    useEffect(() => {
        setLoading(true)
        axios.get('/jobs/api/v1/job/get.php?open&id=' + props.match.params.id)
            .then((response) => {
                if (response.data.Message === "OK" && response.data.Jobs !== false) {
                    setJob(response.data.Jobs)
                } else {
                    props.updateError(response.data.Message)
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
        axios.get('/jobs/api/v1/question/get.php?open&job_id=' + props.match.params.id)
            .then((response) => {
                if (response.data.Message === 'OK') {
                    let newQuestions = { id: [], title: [], question: [], response_length: [], required: [], response: [] }
                    if (response.data.Questions !== null) {
                        response.data.Questions.forEach((Question) => {
                            newQuestions = { id: [...newQuestions.id, Question.id], title: [...newQuestions.title, Question.title], question: [...newQuestions.question, Question.question], response_length: [...newQuestions.response_length, Question.max_resonse_length], required: [...newQuestions.required, Question.required], response: [...newQuestions.response, ''] }
                        })
                        axios.get('/jobs/api/v1/application/get.php?jobApplication&jobId=' + props.match.params.id)
                            .then((response) => {
                                setLoading(false)
                                if (response.data.Message === 'OK') {
                                    if (response.data.Questions !== null && response.data.Questions !== true && response.data.Questions !== undefined) {
                                        response.data.Questions.forEach((Question, i) => {
                                            if (Question.response !== null || undefined) {
                                                let vals = [...newQuestions.response];
                                                let index = newQuestions.id.indexOf(Question.question_id)
                                                vals[index] = Question.response;
                                                newQuestions = { id: [...newQuestions.id], title: [...newQuestions.title], question: [...newQuestions.question], response_length: [...newQuestions.response_length], required: [...newQuestions.required], response: vals }
                                            } else {
                                                let vals = [...newQuestions.response];
                                                let index = newQuestions.id.indexOf(Question.question_id)
                                                vals[index] = null;
                                                newQuestions = { id: [...newQuestions.id], title: [...newQuestions.title], question: [...newQuestions.question], response_length: [...newQuestions.response_length], required: [...newQuestions.required], response: vals }
                                            }
                                        })
                                    }
                                    setDisability(response.data.User.disability)
                                    setQuestions(newQuestions)
                                    setFirstName(response.data.User.first_name)
                                    setLastName(response.data.User.last_name)
                                    setMiddleNames(response.data.User.middle_name)
                                    setProNoun(response.data.User.pronoun)
                                    setPreferredName(response.data.User.preferred_name)
                                    setPhoneNumber(response.data.User.phone)
                                } else if (response.data.Message === "Job Application already submitted") {
                                    setAlreadyApplied(true)
                                } else {
                                    props.updateError(response.data.Message)
                                }
                            }).catch(function (error) {
                                if (error.response) {
                                    props.updateError(error.response.data.Message)
                                } else if (error.request) {
                                    props.updateError(error.request)
                                } else {
                                    props.updateError(error.message)
                                }
                            });
                    } else {
                        axios.get('/jobs/api/v1/application/get.php?jobApplication&jobId=' + props.match.params.id)
                            .then((response) => {
                                setLoading(false)
                                if (response.data.Message === 'OK') {
                                    setDisability(response.data.User.disability)
                                    setFirstName(response.data.User.first_name)
                                    setLastName(response.data.User.last_name)
                                    setMiddleNames(response.data.User.middle_name)
                                    setProNoun(response.data.User.pronoun)
                                    setPreferredName(response.data.User.preferred_name)
                                    setPhoneNumber(response.data.User.phone)
                                } else if (response.data.Message === "Job Application already submitted") {
                                    setAlreadyApplied(true)
                                } else {
                                    props.updateError(response.data.Message)
                                }
                            }).catch(function (error) {
                                if (error.response) {
                                    props.updateError(error.response.data.Message)
                                } else if (error.request) {
                                    props.updateError(error.request)
                                } else {
                                    props.updateError(error.message)
                                }
                            });
                    }
                } else {
                    props.updateError(response.data.Message)
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data.Message)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });

    }, [])
    function RenderJob() {
        if (job !== null) {
            if (loading) {
                return (
                    <Container>
                        <div className="d-flex justify-content-center p-5"><BeatLoader loading={loading} color={'#002F78'}></BeatLoader></div>
                    </Container>
                )
            } else {
                return (
                    <>
                        <div className="job-listing">
                            {job.job_alert ? (
                                <Row>
                                    <Col className="top-bottom-padding">
                                        <p className="jamescape-light-blue p-4">{job.job_alert}</p>
                                    </Col>
                                </Row>
                            ) : (null)}
                            <h3 className="top-bottom-padding">Key Details</h3>
                            <Row>
                                <Col xs={12} sm={4}>
                                    <p><b>Contract: </b>{job.contract}</p>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <p><b>Pay Rate: </b>{job.pay_rate}</p>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <p><b>Reports to: </b>{job.reports_to}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={4}>
                                    <p><b>Location: </b>{job.location}</p>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <p><b>Seniority: </b>{job.seniority}</p>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <p><b>Department: </b>{job.department}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="top-bottom-padding">{job.description}</p>
                                </Col>
                            </Row>
                            {job.Articles ? (job.Articles.map((article, i) =>
                                <div key={i} className="jamescape-light-blue p-4 mt-4">
                                    <Row><Col><h3>{article.title}</h3><pre>{article.content}</pre></Col></Row>
                                    {article.Files.id ? (<Row><Col className="text-center"><img src={"/jobs/api/v1/getFile.php?id=" + article.Files.id} alt={article.Files.name} className="img-fluid" /></Col></Row>) : (null)}
                                </div>
                            )) : null}
                        </div>
                    </>
                )
            }
        } else {
            return null;
        }
    }
    function FullLegalName() {
        return (
            <>
                <Row>
                    <Col md={true}>
                        <Form.Group className={validation.firstName ? "invalid" : validation.section > 0 ? "was-validated" : null}>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} maxLength={100} />
                            {validation.firstName ? <Form.Label className="red-text">Invalid First Name</Form.Label> : null}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={true}>
                        <Form.Group className={validation.section > 0 ? "was-validated" : null}>
                            <Form.Label>Middle Name(s)</Form.Label>
                            <Form.Control type="text" placeholder="Middle Name(s)" value={middleNames} onChange={(e) => setMiddleNames(e.target.value)} maxLength={100} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={true}>
                        <Form.Group className={validation.lastName ? "invalid" : validation.section > 0 ? "was-validated" : null}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} maxLength={100} />
                            {validation.lastName ? <Form.Label className="red-text">Invalid Last Name</Form.Label> : null}
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }
    const PreferredName = () => {
        return (
            <>
                <Row>
                    <Col md={true}>
                        <Form.Group className={validation.proNoun ? "invalid" : validation.section > 1 ? "was-validated" : null}>
                            <Form.Label>Preferred Pronoun(s)</Form.Label>
                            <Form.Control type="text" placeholder="Pronoun(s)" value={proNoun} onChange={(e) => setProNoun(e.target.value)} maxLength={20} />
                            {validation.proNoun ? <Form.Label className="red-text">Invalid Pronoun</Form.Label> : null}
                        </Form.Group>
                    </Col>
                    <Col md={true}>
                        <Form.Group className={validation.preferredName ? "invalid" : validation.section > 1 ? "was-validated" : null}>
                            <Form.Label>Preffered Name(s)</Form.Label>
                            <Form.Control type="text" placeholder="Preffered Name(s)" value={preferredName} onChange={(e) => setPreferredName(e.target.value)} maxLength={100} />
                            {validation.preferredName ? <Form.Label className="red-text">Invalid Preferred Name</Form.Label> : null}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={true}>
                        <Form.Group className={validation.preferredName ? "invalid" : validation.section > 1 ? "was-validated" : null}>
                            <p>We guarantee to interview all disabled applicants who meet the essential criteria for the post and consider them on their abilities. Should you wish your application to be considered under this scheme, please select Yes. Please note, you must have a disability (as defined by the Equality Act 2010) in order to be eligible. You do not need to declare your particular disability to us to be eligible.</p>
                            <Form.Label>Disability Gauranteed Interview Scheme</Form.Label>
                            <Form.Check type="checkbox" label="Yes" checked={disability} onChange={(e) => setDisability(e.target.checked)} />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }
    const PhoneNumber = () => {
        return (
            <>
                <Row>
                    <Col md={true}>
                        <Form.Group className={validation.phoneNumber ? "invalid" : validation.section > 2 ? "was-validated" : null}>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} maxLength="11" />
                            {validation.section > 2 && validation.phoneNumber ? <Form.Label className="red-text">Invalid Phone Number</Form.Label> : null}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a className={"clickable-link pb-2"} onClick={() => setPhoneNumberCollapse(!phoneNumberCollapse)}>Don't have a phone number?</a>
                        <Collapse in={phoneNumberCollapse}>
                            <div>
                                <p>Please email <a href="mailto:jobs@jamescape.net">Jamescape Jobs</a> if you do not have a phone number</p>
                            </div>
                        </Collapse>
                    </Col>
                </Row>
            </>
        )
    }

    function Dropzone() {
        let allowedFiles = 0
        if (job.uploads_allowed > 0) {
            allowedFiles = job.uploads_allowed
        }
        const {
            acceptedFiles,
            getRootProps,
            getInputProps
        } = useDropzone({
            maxFiles: allowedFiles,
            accept: '.png,.jpeg,.jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            onDrop: acceptedFiles => {
                if (job.uploads_allowed - files.length !== 0) {
                    setFiles(files => files.concat(acceptedFiles))
                }
            }
        });
        function RemoveFile(i) {
            setFiles(files => files.filter(file => file.path !== files[i].path))
        }
        function AcceptedFileItems() {
            return files.map((file, i) => (
                <li key={file.path}>
                    {file.path} - <button type="button" className="jamescape-red-button" onClick={() => RemoveFile(i)}>Remove File</button>
                </li>
            ));
        }



        if (allowedFiles === 0) {
            return <h1>No files are required for this job application</h1>
        } else {
            return (
                <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        <em>({allowedFiles - files.length} files are the maximum number of files you can upload)</em>
                    </div>
                    <aside>
                        <h4>Uploaded File(s)</h4>
                        <ul><AcceptedFileItems /></ul>
                    </aside>
                </section>
            );
        }
    }
    function RenderQuestions() {
        function updateResponse(event) {
            let vals = [...questions.response];
            vals[this] = event.target.value;
            setQuestions({ id: [...questions.id], title: [...questions.title], question: [...questions.question], response_length: [...questions.response_length], required: [...questions.required], response: vals })
        }
        if (questions.id.length === 0) {
            return <h1>There are no questions for this job</h1>
        }
        return questions.id.map((val, i) =>
            <div key={i} className={questionValidation.indexOf(i) !== -1 ? "jamescape-red p-4 mb-4 mt-4" : "jamescape-light-blue p-4 mb-4 mt-4"}
            >
                <Row>
                    <Col>
                        <h2>{questions.title[i]}{questions.required[i] ? null : " (Optional)"}</h2>
                        <br>
                        </br>
                        <h4>{questions.question[i]}</h4>
                        <Form.Group>
                            <Form.Control as="textarea" rows={6} placeholder="Your Response" value={questions.response[i]} onChange={updateResponse.bind(i)} maxLength={questions.response_length[i]} onKeyDown={() => {
                                if (saveQuestion.indexOf(i) < 0) {
                                    setSaveQuestion([...saveQuestion, i])
                                }
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        );
    }
    return (
        <>
            <button type="button" className="jamescape-blue-button mt-4 mb-4" onClick={history.goBack}>Back</button>
            <input value={displayValidation} type="hidden" />
            {loading ? <div className="d-flex justify-content-center p-5"><BeatLoader loading={loading} color={'#002F78'}></BeatLoader></div> :

                alreadyApplied ? <h1>You have already applied to this job</h1> :
                    <>
                        <Row>
                            <Col>
                                <h2>Apply For {job.title}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="jamescape-light-blue p-4">
                                <a className="clickable-link" onClick={() => setOpen(!open)}><h3>{open ? <><FontAwesomeIcon icon={faAngleDown} />&nbsp;</> : <><FontAwesomeIcon icon={faAngleRight} />&nbsp;</>} View job description</h3></a>
                            </Col>
                        </Row>
                        <Collapse in={open}>
                            <div>
                                <RenderJob />
                            </div>
                        </Collapse>
                        <Container className="mt-2">
                            {formProgress === 0 ? FullLegalName() : null}
                            {formProgress === 1 ? PreferredName() : null}
                            {formProgress === 2 ? PhoneNumber() : null}
                            {formProgress === 3 ? saving === 0 ? null : saving : null}
                            {formProgress === 3 ? RenderQuestions() : null}
                            {formProgress === 4 ? <Dropzone /> : null}
                            <Row>
                                <Col className="mb-4">
                                    {formProgress === 0 ? null : <button type="button" className="jamescape-blue-button" onClick={() => setFormProgress(formProgress - 1)}>Back</button>}
                                    {formProgress === 4 ? <button type="button" className="jamescape-blue-button float-right" onClick={() => SubmitApplication()}>{update ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : 'Submit Application'}</button> : <button type="button" className="jamescape-blue-button float-right" onClick={() => Validate()}>{update ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : formProgress === 4 ? 'Apply' : 'Next'}</button>}
                                </Col>
                            </Row>
                        </Container>
                    </>
            }
        </>
    )
}