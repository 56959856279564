import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import AdminOpenJobs from './AdminOpenJobs';
import AdminClosedJobs from './AdminClosedJobs';
export default function AllJobs(props) {
    const [openJobs, setOpenJobs] = useState(false);
    return (
        <>
            <Row>
                <Col>
                    <ButtonGroup toggle >
                        <ToggleButton
                            type="radio"
                            name="radio"
                            className="jamescape-blue-button m-4"
                            value={false}
                            checked={openJobs === false}
                            onChange={() => setOpenJobs(false)}
                        >
                        Open Jobs
                        </ToggleButton>
                        <ToggleButton
                            type="radio"
                            name="radio"
                            className="jamescape-blue-button m-4"
                            value={false}
                            checked={openJobs === true}
                            onChange={() => setOpenJobs(true)}
                        >
                        Closed Jobs
                        </ToggleButton>
                    </ButtonGroup>
                    <Link to="/job/create"><button type="button" className="jamescape-blue-button float-right mt-4">Add Job</button></Link>
                </Col>
            </Row>
            {openJobs ? <AdminClosedJobs updateError={props.updateError}/> : <AdminOpenJobs updateError={props.updateError}/>}
        </>
    )
}