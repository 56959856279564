import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';
import BeatLoader from 'react-spinners/BeatLoader';
import Container from 'react-bootstrap/esm/Container';
import history from '../utilities/History'
export default function JobPosting(props) {
    const [job, setJob] = useState([])
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        props.topPageRef.current.scrollIntoView({ behavior: "smooth" })
        if (Cookies.get('Session') === "Staff") {
            axios.get('/jobs/api/v1/job/get.php?id=' + props.match.params.id)
                .then((response) => {
                    if (response.data.Message === "OK" && response.data.Jobs !== false) {
                        setJob(response.data.Jobs)
                        setLoading(!loading);
                    } else {
                        props.updateError("No Job with that ID exists")
                        setLoading(!loading);
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
        } else {
            axios.get('/jobs/api/v1/job/get.php?open&id=' + props.match.params.id)
                .then((response) => {
                    if (response.data.Message === "OK" && response.data.Jobs !== false) {
                        setJob(response.data.Jobs)
                        setLoading(!loading);
                    } else {
                        props.updateError("No Job with that ID exists")
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
        }
    }, [])
    function RenderJob() {
        if (job !== null) {
            if (loading) {
                return (
                    <Container>
                        <div className="d-flex justify-content-center p-5"><BeatLoader loading={loading} color={'#002F78'}></BeatLoader></div>
                    </Container>
                )
            } else {
                return (
                    <>
                        <button type="button" className="jamescape-blue-button mb-4 mt-4" onClick={() => history.goBack()}>Back</button>
                        <div className="job-listing">
                            <Row>
                                <Col>
                                    <h2>{job.title}</h2>
                                </Col>
                            </Row>
                            {job.job_alert ? (
                                <Row>
                                    <Col className="top-bottom-padding">
                                        <p className="jamescape-light-blue p-4">{job.job_alert}</p>
                                    </Col>
                                </Row>
                            ) : (null)}
                            <h3 className="top-bottom-padding">Key Details</h3>
                            <Row>
                                <Col>
                                    <p><b>Contract: </b>{job.contract}</p>
                                </Col>
                                <Col>
                                    <p><b>Pay Rate: </b>{job.pay_rate}</p>
                                </Col>
                                <Col>
                                    <p><b>Reports to: </b>{job.reports_to}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p><b>Location: </b>{job.location}</p>
                                </Col>
                                <Col>
                                    <p><b>Seniority: </b>{job.seniority}</p>
                                </Col>
                                <Col>
                                    <p><b>Department: </b>{job.department}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p className="top-bottom-padding">{job.description}</p>
                                </Col>
                            </Row>
                            {job.Articles ? (job.Articles.map((article, i) =>
                                <div key={i} className="jamescape-light-blue p-4 mt-4">
                                    <Row><Col><h3>{article.title}</h3><pre>{article.content}</pre></Col></Row>
                                    {article.Files.id ? (<Row><Col className="text-center"><img src={"/jobs/api/v1/getFile.php?id=" + article.Files.id} alt={article.Files.name} className="img-fluid" /></Col></Row>) : (null)}
                                </div>
                            )) : null}
                            <Link to={"/job/" + job.id + "/apply"}><button type="button" className="jamescape-blue-button mt-4">Apply Now</button></Link>
                        </div>
                    </>
                )
            }
        } else {
            return null;
        }
    }
    return (
        <>
            <RenderJob />
        </>
    )
}