import React, { useState, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import axios from 'axios'
import Container from 'react-bootstrap/Container';
export default function AcceptJob(props)
{
    const [application, setApplication] = useState(false)
    const [accepting, setAccepting] = useState(false)
    useEffect(() => {
        axios.post('/jobs/api/v1/application/get.php?getOffers&jobId=' + props.match.params.id)
        .then((response) => {
            if (response.data.Message === "OK") {
                props.updateError(null)
                setApplication(response.data.title)
            } else {
                props.updateError("You do not have an offer for that job")
            }
        }).catch(function (error) {
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }, [])
    function AcceptJob(){
        setAccepting(true)
        axios.post('/jobs/api/v1/application/update.php', {
            jobId: props.match.params.id,
            accept: true
        }).then((response) => {
            if (response.data.Message === "OK") {
                setAccepting(false)
                props.updateError("Job successfully accepted, we will be in touch");
            } else {
                setAccepting(false)
                props.updateError("Error accepting job")
            }
        }).catch(function (error) {
            setAccepting(false)
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    return (
        <Container>
            {application === false ? null : <><h1>Would you like to accept the offer for the position of {application}?</h1><button type="button" className="jamescape-blue-button mx-auto" onClick={() => AcceptJob()}>{accepting ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Accept Job"}</button></>}
        </Container>
    )
}
