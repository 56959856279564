import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom"
import Container from 'react-bootstrap/Container';
import BeatLoader from 'react-spinners/BeatLoader';
import history from '../utilities/History'
import axios from 'axios';
import ShowApplication from './ShowApplication';
export default function JobApplications(props) {
    const [applications, setApplications] = useState([])
    const [job, setJob] = useState(false)
    const [message, setMessage] = useState(false)
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios.get('/jobs/api/v1/application/get.php?jobApplication&allApplications&jobId=' + props.match.params.id)
            .then((response) => {
                if (response.data.Message === "OK") {
                    props.updateError(null)
                    setApplications(response.data.Applications)
                    setLoading(false);
                } else {
                    setApplications([])
                    setMessage(response.data.Message)
                    setLoading(false);
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
        axios.get('/jobs/api/v1/job/get.php?id=' + props.match.params.id)
            .then((response) => {
                if (response.data.Message === "OK") {
                    props.updateError(null)
                    setJob(response.data.Jobs)
                    setLoading(false);
                } else {
                    setApplications([])
                    setMessage(response.data.Message)
                    setLoading(false);
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
    }, [])

    return (
        <>
            <h1 className="text-center">Applications to {job.title}</h1>
            <button type="button" className="jamescape-blue-button mb-4" onClick={history.goBack}>Back</button>
            {loading ? <div className="d-flex justify-content-center p-5"><BeatLoader loading={loading} color={'#002F78'}></BeatLoader></div> :
                <>
                    <h1>{message}</h1>
                    { applications.map((application, i) =>
                        <div key={i} className="job-listing">
                            <ShowApplication updateError={props.updateError} application={application} />
                        </div>
                    )}
                </>
            }
        </>
    )
}