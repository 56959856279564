import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import axios from 'axios';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {faAngleDown} from '@fortawesome/free-solid-svg-icons'
export default function Application(props) {
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    function Withdraw() {
        axios.post('/jobs/api/v1/application/withdraw.php', {
            id: props.jobId
        }).then((response) => {
            if (response.data.Message === "OK") {
                props.reload()
                setShow(false)
            } else {
                props.updateError(response.data.Message)
            }
        }).catch(function (error) {
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    return (
        <>
            <Container>
                <Row>
                    <Col onClick={() => setOpen(!open)} className="clickable-link">
                        <h2>{open ? <><FontAwesomeIcon icon={faAngleDown}/>&nbsp;</> : <><FontAwesomeIcon icon={faAngleRight}/>&nbsp;</> }{props.title}</h2>
                    </Col>
                    <Col>
                        <ButtonGroup>
                            {props.status !== "Submitted" ? null : <Link><button type="button" className="jamescape-transparant-button float-right ml-2">Application Submitted</button></Link>}
                            {props.status === "Offer" ? <Link to={"/job/" + props.jobId + "/accept/"}><button type="button" className="jamescape-blue-button float-right ml-2">Accept Offer</button></Link> : null}
                            {props.status === "Withdrawn" ? <p className="jamescape-transparant-button float-right ml-2">Application Withdrawn</p> : null}
                            {props.status === "Interview Offered" ? <p className="jamescape-transparant-button float-right ml-2">Interview Offered</p> : null}
                            {props.status === "Rejected" ? <p className="jamescape-transparant-button float-right ml-2">Application Rejected</p> : null}
                            {props.r2w_status === "Requested" ? <Link to={"/right-to-work/"}><button type="button" className="jamescape-blue-button float-right ml-2">Complete R2W Form</button></Link> : null}
                            {props.status === "Withdrawn" || props.status === "Accepted" || props.status === 'Rejected' ? null : <Link><button type="button" className="jamescape-red-button  float-right ml-2" onClick={() => setShow(true)}>Withdraw Application</button></Link>}
                            {props.status === "In Progress" ? <Link to={"/job/" + props.jobId + "/apply/"}><button type="button" className="jamescape-blue-button float-right ml-2">Resume Application</button></Link> : null}
                            {props.status !== "Accepted" ? null : <Link><button type="button" className="jamescape-transparant-button float-right ml-2">Job Accepted</button></Link>}
                        </ButtonGroup>
                    </Col>
                </Row>
                <Collapse in={open}>
                    <div>
                        <h2>{props.applicant ? "Your responses:" : null}</h2>
                        {props.questions === null ? null :
                            props.questions.map((question, i) =>
                                <div key={i} className="jamescape-light-blue p-4 mb-4 mt-4">
                                    <Row>
                                        <Col>
                                            <h2>{question.title}{question.required ? null : " (Optional)"}</h2>
                                            <br>
                                            </br>
                                            <h4>{question.question}</h4>
                                            <Form.Group>
                                                <Form.Control as="textarea" rows={6} placeholder="Your Response" value={question.response} disabled />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        <Row>
                            <Col>
                                <button type="button" className="jamescape-blue-button float-right mb-4" onClick={() => setOpen(!open)}>Hide</button>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </Container>
            <Modal
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Application Withdrawl</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to withdraw your application?</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="jamescape-blue-button float-right ml-2" onClick={() => setShow(false)}>Cancel</button>
                    <button type="button" className="jamescape-red-button float-right ml-2" onClick={() => Withdraw()}>Withdraw Application</button>                </Modal.Footer>
            </Modal>

        </>
    )
}