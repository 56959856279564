import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button'
import ApplicantButtons from './ApplicantButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
export default function ShowApplication(props) {
    const [open, setOpen] = useState(false)
    return (
        <Container>
            <Row>
                <Col md={4} onClick={() => setOpen(!open)} className="clickable-link">
                    <h2>{open ? <><FontAwesomeIcon icon={faAngleDown} />&nbsp;</> : <><FontAwesomeIcon icon={faAngleRight} />&nbsp;</>}Applicant {props.application.first_name} {props.application.last_name}</h2>
                </Col>
                <Col md={8}>
                    <ApplicantButtons className="my-auto" updateError={props.updateError} applicantId={props.application.applicant_id} status={props.application.status} applicationId={props.application.application_id} firstName={props.application.first_name} lastName={props.application.last_name} r2wStatus={props.application.r2wStatus} />
                </Col>
            </Row>
            <Collapse in={open}>
                <div>
                    <Row>
                        <Col sm={12} md={4}>
                            <p><b>First Name: </b>{props.application.first_name}</p>
                        </Col>
                        <Col sm={12} md={4}>
                            <p><b>Middle Name: </b>{props.application.middle_name}</p>
                        </Col>
                        <Col sm={12} md={4}>
                            <p><b>Last Name: </b>{props.application.last_name}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4}>
                            <p><b>Preferred Name: </b>{props.application.preferred_name}</p>
                        </Col>
                        <Col sm={12} md={4}>
                            <p><b>Pronoun/s: </b>{props.application.pronoun}</p>
                        </Col>
                        <Col sm={12} md={4}>
                            <p><b>Phone Number: </b>{props.application.phone}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4}>
                            <p><b>Disability Guaranteed Interview Scheme: </b><Form.Check type="checkbox" checked={props.application.disability}/></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4}>
                            <p><b>Email Address: </b>{props.application.email}</p>
                        </Col>
                    </Row>
                    {props.application.Questions !== null ?
                        props.application.Questions.map((question, i) =>
                            <div key={i} className="jamescape-light-blue p-4 mb-4 mt-4">
                                <Row>
                                    <Col>
                                        <h2>{question.title}{question.required ? null : " (Optional)"}</h2>
                                        <br>
                                        </br>
                                        <h4>{question.question}</h4>
                                        <Form.Group>
                                            <Form.Control as="textarea" rows={6} placeholder="Your Response" value={question.response} disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                    {props.application.Files === null ? null : <h3>Uploaded Files</h3>}
                    {props.application.Files === null ? null : props.application.Files.map((file, i) =>
                        <div key={i} className="jamescape-light-blue p-4 mb-4 mt-4">
                            <Row>
                                <Col>
                                    <h2 className="float-left"><a href={"/jobs/api/v1/getFile.php?id=" + file.file_id}>{file.name}</a></h2>
                                    <button type="button" onClick={() => window.location.replace("/jobs/api/v1/getFile.php?id=" + file.file_id)} className="jamescape-blue-button float-right">Download</button>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <button type="button" className="jamescape-blue-button float-right mb-4" onClick={() => setOpen(!open)}>Hide</button>
                </div>
            </Collapse>
        </Container>
    )
}