import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { SketchPicker } from 'react-color'
import axios from 'axios'
import BeatLoader from 'react-spinners/BeatLoader';

export default function ManageAlerts(props) {
    const [alerts, setAlerts] = useState({ id: [], content: [], colour: [], updatedAt: [], createdAt: [] })
    const [updated, setUpdated] = useState([])
    const [saving, setSaving] = useState(false)
    useEffect(() => {
        axios.get('/jobs/api/v1/alert/get.php')
            .then((response) => {
                if (response.data.Message === "OK") {
                    props.updateError(null)
                    let newAlerts = { id: [], content: [], colour: [], updatedAt: [], createdAt: [] }
                    let newUpdated = []
                    response.data.Alerts.forEach(alert => {
                        newUpdated.push(false)
                        newAlerts = { id: [...newAlerts.id, alert.id], content: [...newAlerts.content, alert.content], colour: [...newAlerts.colour, alert.colour], updatedAt: [...newAlerts.updatedAt, alert.updated_at], createdAt: [...newAlerts.createdAt, alert.created_at] }
                    });
                    setUpdated(newUpdated)
                    setAlerts(newAlerts)
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
    }, [])
    function setContent(event) {
        let tempUpdated = updated
        tempUpdated[this] = true
        setUpdated(tempUpdated)
        let vals = [...alerts.content];
        vals[this] = event.target.value;
        setAlerts({ id: [...alerts.id], content: vals, colour: [...alerts.colour], updatedAt: [...alerts.updatedAt], createdAt: [...alerts.createdAt] })
    }
    function setColour(colour, event) {
        let tempUpdated = updated
        tempUpdated[this] = true
        setUpdated(tempUpdated)
        let vals = [...alerts.colour];
        vals[this] = colour.hex;
        setAlerts({ id: [...alerts.id], content: [...alerts.content], colour: vals, updatedAt: [...alerts.updatedAt], createdAt: [...alerts.createdAt] })
    }
    const addAlert = () => {
        setAlerts({ id: [...alerts.id, ''], content: [...alerts.content, ''], colour: [...alerts.colour, ''], updatedAt: [...alerts.updatedAt, ''], createdAt: [...alerts.createdAt, ''] })
    }
    function deleteAlert(i) {
        if (alerts.id[i] !== '') {
            axios.post('/jobs/api/v1/alert/delete.php', {
                alert_id: alerts.id[i]
            }).then((response) => {
                if (response.data.Message === "OK") {
                    let id = [...alerts.id]
                    id.splice(i, 1)
                    let content = [...alerts.content]
                    content.splice(i, 1)
                    let colour = [...alerts.colour]
                    colour.splice(i, 1)
                    let updatedAt = [...alerts.updatedAt]
                    updatedAt.splice(i, 1)
                    let createdAt = [...alerts.createdAt]
                    createdAt.splice(i, 1)
                    let updatedArray = updated
                    updatedArray.splice(i, 1)
                    setUpdated(updatedArray)
                    setAlerts({ id: id, content: content, colour: colour, updatedAt: updatedAt, createdAt: createdAt })
                    props.updateError(null)
                } else {
                    props.updateError("Error deleting alert")
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
        } else {
            let id = [...alerts.id]
            id.splice(i, 1)
            let content = [...alerts.content]
            content.splice(i, 1)
            let colour = [...alerts.colour]
            colour.splice(i, 1)
            let updatedAt = [...alerts.updatedAt]
            updatedAt.splice(i, 1)
            let createdAt = [...alerts.createdAt]
            createdAt.splice(i, 1)
            let updatedArray = updated
            updatedArray.splice(i, 1)
            setUpdated(updatedArray)
            setAlerts({ id: id, content: content, colour: colour, updatedAt: updatedAt, createdAt: createdAt })
        }

    }
    function Save(i) {
        if (alerts.content[i] === "") {
            alert("Invalid Alert content")
            return
        } else if (alerts.colour[i] === "") {
            alert("Please select an Alert colour")
            return
        } else {
            setSaving(i)
        }
        if (alerts.id[i] === '') {
            axios.post('/jobs/api/v1/alert/create.php', {
                content: alerts.content[i],
                colour: alerts.colour[i]
            }).then((response) => {
                if (response.data.Message === "OK") {
                    let updatedArray = updated
                    updatedArray[i] = false
                    setUpdated(updatedArray)
                    setSaving(false)
                    props.updateError(null)
                } else {
                    setSaving(false)
                    props.updateError("Error adding alert")
                }
            }).catch(function (error) {
                setSaving(false)
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
        } else {
            axios.post('/jobs/api/v1/alert/update.php', {
                content: alerts.content[i],
                colour: alerts.colour[i],
                alert_id: alerts.id[i]
            }).then((response) => {
                if (response.data.Message === "OK") {
                    let updatedArray = updated
                    updatedArray[i] = false
                    setUpdated(updatedArray)
                    setSaving(false)
                    props.updateError(null)
                } else {
                    setSaving(false)
                    props.updateError("Error adding alert")
                }
            }).catch(function (error) {
                setSaving(false)
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
        }
    }
    return (
        <>
            <Container>
                <h1>Alerts</h1>
                {alerts.id.map((val, i) =>
                    <div key={i} className="jamescape-light-blue p-4 mb-4 mt-4">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Alert Content</Form.Label>
                                    <Form.Control as="textarea" rows={10} key="articleContent" placeholder="Alert Content" value={alerts.content[i] || ''} onChange={setContent.bind(i)} maxLength="500" />
                                </Form.Group>

                            </Col>
                            <Col>
                                <SketchPicker
                                    color={alerts.colour[i]}
                                    onChange={setColour.bind(i)}
                                    presetColors={['#c41d1d', '#002F78', '#F0F4FF']}
                                    className="mx-auto"
                                />
                                <button type='button' className="jamescape-red-button float-right ml-2 mt-4" onClick={() => deleteAlert(i)}>Delete</button>
                                {updated[i] === true ? <button type='button' className="jamescape-blue-button float-right mt-4" onClick={() => Save(i)}>{saving === i ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Save"}</button>
                                    :
                                    <button disabled type='button' className="jamescape-blue-button float-right mt-4" onClick={() => Save(i)}>{saving === i ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Save"}</button>}
                            </Col>
                        </Row>
                    </div>
                )}
                <Row>
                    <Col>
                        <button type='button' className="jamescape-blue-button float-right mb-4" onClick={() => addAlert()}>Add Alert</button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}