import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BeatLoader from 'react-spinners/BeatLoader';
import Container from 'react-bootstrap/esm/Container';
import ConvertTimestamp from './../utilities/ConvertTimestamp';
import CloseJobButton from './CloseToApplicants';
export default function AdminClosedJobs(props) {
    const [jobs, setJobs] = useState([]);
    const [closeButton, setCloseButton] = useState(false)
    const [id, setId] = useState(false)
    const [noJobs, setNoJobs] = useState(null)
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        console.log(Cookies.get('Session'))
        console.log(Cookies.get('Session') === 'Staff');
        props.updateError(null)
        if (!closeButton) {
            if (Cookies.get('Session') === "Staff") {
                axios.get('/jobs/api/v1/job/get.php?all&closed')
                    .then((response) => {
                        if (response.data.Message === "OK") {
                            setJobs(response.data.Jobs)
                            setLoading(false);
                        } else {
                            setJobs(null)
                            setNoJobs('There are currenly no jobs at Jamescape')
                        }
                    }).catch(function (error) {
                        if (error.response) {
                            props.updateError(error.response.data)
                        } else if (error.request) {
                            props.updateError(error.request)
                        } else {
                            props.updateError(error.message)
                        }
                    })
            } else {
                props.updateError("You must be a member of staff to do that");
            }
        }
    }, [closeButton])
    if (jobs !== null) {
        if (loading) {
            return (
                <Container>
                    <div className="d-flex justify-content-center p-5"><BeatLoader loading={loading} color={'#002F78'}></BeatLoader></div>
                </Container>
            )
        } else {
            return (
                <>
                    <h2>{noJobs}</h2>
                    { jobs.map((job, i) =>
                        <div key={i} className="job-listing">
                            <div className="job-listing">
                                <Row>
                                    <Col>
                                        <h2>{job.title}</h2>
                                    </Col>
                                </Row>
                                <h3 className="top-bottom-padding">Key Details</h3>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <p><b>Contract: </b>{job.contract}</p>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <p><b>Pay Rate: </b>{job.pay_rate}</p>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <p><b>Reports to: </b>{job.reports_to}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <p><b>Location: </b>{job.location}</p>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <p><b>Seniority: </b>{job.seniority}</p>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <p><b>Department: </b>{job.department}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <p><b>Opening At: </b>{ConvertTimestamp(job.opening_at)}</p>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <p><b>Closing to applicants at: </b>{ConvertTimestamp(job.closing_at)}</p>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <p><b>Created At: </b>{ConvertTimestamp(job.created_at)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <p><b>Updated At: </b>{ConvertTimestamp(job.updated_at)}</p>
                                    </Col>
                                </Row>
                                <Link to={"/job/" + job.id}><button type="button" className="jamescape-blue-button">View Job</button></Link>
                                <Link to={"/job/applications/" + job.id}><button type="button" className="jamescape-blue-button ml-2">View Applicants</button></Link>
                            </div>
                        </div>
                    )}
                </>
            )
        }
    } else {
        return <h1>No closed jobs at Jamescape :(</h1>;
    }
}