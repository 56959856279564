import axios from "axios";
import history from './History'
import { useEffect } from 'react';
export default function VerifyToken(props)
{
    useEffect(() => {
        axios.post('/jobs/api/v1/auth/applicantLogin.php', {
            token: props.match.params.token
        }).then((response) => {
            if(response.data.Message === 'OK'){
                history.push(response.data.Redirect.path)
            } else {
                props.updateError("Invalid Token, Please enter your email address again")
                history.push('/applicant/login')
            }
        })
    }, [])
    return null;
}