import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
export default function AlertGenerator(props) {
    const [alerts, setAlerts] = useState([])
    const alertRef = useRef(null)
    const errorAlertRef = useRef(null)
    useEffect(() => {
        axios.get('/jobs/api/v1/alert/get.php')
            .then((response) => {
                if (response.data.Message === "OK") {
                    setAlerts(response.data.Alerts)
                } else {
                    setAlerts(null)
                }
            }).catch((error) => {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.response.data)
                }
            })
    }, [props])
    useEffect(() => {
        if (props.error !== undefined && props.error !== null) {
            errorAlertRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }, [props.error])
    function pickTextColorBasedOnBgColorAdvanced(bgColor) {
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];
        var c = uicolors.map((col) => {
            if (col <= 0.03928) {
                return col / 12.92;
            }
            return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        return (L > 0.179) ? "#000000" : "#FFFFFF";
    }
    if (props.location.pathname === "/alerts" || props.location.pathname === "/alerts/") {
        if (props.error !== undefined && props.error !== null) {
            return (
                <>
                    <Row>
                        <Col>
                            <Row><Col className="p-0"><Alert className="jamescape-alert jamescape-light-blue text-center">{props.error}</Alert></Col></Row>
                        </Col>
                    </Row>
                </>
            );
        } else {
            return null;
        }
    } else {
        function RenderError(props) {
            if (props.error !== undefined && props.error !== null) {
                return (
                    <Row><Col className="p-0"><Alert className="jamescape-alert jamescape-light-blue text-center">{props.error}</Alert></Col></Row>
                )
            } else {
                return null;
            }
        }
        function RenderErrorNotifications(props) {
            if (alerts !== null) {
                return (
                    <>
                        { alerts.map((alert, i) => <Row key={i}><Col className="p-0"><Alert style={{ backgroundColor: alert.colour, color: pickTextColorBasedOnBgColorAdvanced(alert.colour) }} className="jamescape-alert"><p>{alert.content}</p><div><p className="mb-0 text-right">Last updated {alert.updated_at}</p></div></Alert></Col></Row>)}
                    </>
                )
            } else {
                return null;
            }
        }
        return (
            <>
                <Row ref={alertRef}>
                    <Col>
                        <RenderErrorNotifications error={props.error} />
                    </Col>
                </Row>
                <Row ref={errorAlertRef}>
                    <Col>
                        <RenderError error={props.error} />
                    </Col>
                </Row>
            </>
        );
    }
}