import React, { useState } from 'react';
import Route from "react-router-dom/Route"
import history from './History'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Cookies from 'js-cookie'
import axios from 'axios'
import BeatLoader from 'react-spinners/BeatLoader';
import {
    NavLink,
    Link
} from "react-router-dom"
import msLogin from '../resources/login-with-ms.svg'

export default function StaffOnly(props) {
    let isAuthenticated = false
    let cookie = Cookies.get('Session')
    if (cookie === 'Staff') {
        isAuthenticated = true;
    }
    if (isAuthenticated) {
        return (<Route exact path={props.path} render={props.render} />)
    } else {
        return (<Route exact path={props.path}><StaffLogin url={props.path} /></Route>)
    }
}
function Protected(props) {
    let isAuthenticated = false
    let cookie = Cookies.get('Session')
    if (cookie === 'Staff' || cookie === 'Applicant') {
        isAuthenticated = true;
    }
    if (isAuthenticated) {
        return (<Route exact path={props.path} render={props.render} component={props.component} />)
    } else {
        return (<Route exact path={props.path}><ApplicantLogin url={props.location} /></Route>)
    }
}
function StaffLogin(props) {
    axios.get('/jobs/api/v1/auth/login.php?url=' + encodeURI(props.url))
        .then((response) => {
            if (response.data.Message === 'Redirect') {
                window.location.href = response.data.URL
            } else if (response.data.Message === 'OK') {
                history.push(response.data.URL)
            }
        })
    return (
        null
    )
}
function ApplicantLogin(props) {
    let cookie = Cookies.get('Session')
    let isAuthenticated
    if (cookie === 'Staff' || cookie === 'Applicant') {
        isAuthenticated = true;
    }
    if (isAuthenticated) {
        history.push('/')
    }
    const [email, setEmail] = useState('')
    const [newAccount, setNewAccount] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('warning')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [emailSending, setEmailSending] = useState(false)
    let url
    if (props.url === undefined) {
        url = '/'
    } else {
        url = props.url.pathname
    }
    function requestToken(e) {
        e.preventDefault()
        if (email.length === 0) {
            setAlert("Invalid Email");
        }
        setEmailSending(true)
        if (newAccount === false) {
            axios.post('/jobs/api/v1/auth/applicantLogin.php', {
                email: email,
                path: url
            }).then((response) => {
                if (response.data.Message === 'OK') {
                    setAlertVariant("success")
                    setAlert("To login, follow the link sent to your email")
                    setEmailSending(false)
                } else if (response.data.Message === 'New Account') {
                    setEmailSending(false)
                    setNewAccount(true)
                } else {
                    setAlert(response.data.Message)
                    setEmailSending(false)
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                    setEmailSending(false)
                } else if (error.request) {
                    props.updateError(error.request)
                    setEmailSending(false)
                } else {
                    props.updateError(error.message)
                    setEmailSending(false)
                }
            });
        } else {
            axios.post('/jobs/api/v1/auth/applicantLogin.php', {
                email: email,
                first_name: firstName,
                last_name: lastName,
                path: url
            }).then((response) => {
                if (response.data.Message === 'OK') {
                    setAlertVariant("success")
                    setAlert("To login, follow the link sent to your email")
                    setEmailSending(false)
                } else if (response.data.Message === 'New Account') {
                    setNewAccount(true)
                    setEmailSending(false)
                } else {
                    setAlert(response.data.Message)
                    setEmailSending(false)
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                    setEmailSending(false)
                } else if (error.request) {
                    props.updateError(error.request)
                    setEmailSending(false)
                } else {
                    props.updateError(error.message)
                    setEmailSending(false)
                }
            });
        }
    }

    return (
        <Container>
            <Form className="mt-5" onSubmit={(e) => requestToken(e)}>
                {alert ? <Alert variant={alertVariant}>{alert}</Alert> : null}
                <Form.Group>
                    <Form.Label>Your Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} maxLength="200" required />
                </Form.Group>
                {newAccount ? (
                    <>
                        <Alert variant={'success'}>Please enter your first and last name.</Alert>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} maxLength="100" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} maxLength="100" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                ) : (null)}
                <button type="submit" className="jamescape-blue-button float-right">{emailSending ? (<div className="beat-loader"><BeatLoader loading={true} color={'#FFFFFF'}></BeatLoader></div>) : ('Submit')}</button>
            </Form>
        </Container>
    )
}
export { StaffLogin, Protected, ApplicantLogin };