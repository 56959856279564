import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Datetime from 'react-datetime';
import history from '../utilities/History'

export default function CreateJob(props) {
    const [title, setTitle] = useState("")
    const [department, setDepartment] = useState("")
    const [description, setDescription] = useState("")
    const [uploadsAllowed, setUploadsAllowed] = useState("")
    const [jobAlert, setJobAlert] = useState("")
    const [contract, setContract] = useState("")
    const [location, setLocation] = useState("")
    const [payRate, setPayRate] = useState("")
    const [seniority, setSeniority] = useState("")
    const [reportsTo, setReportsTo] = useState("")
    const [openingAt, setOpeningAt] = useState(new Date())
    const [closingAt, setClosingAt] = useState(new Date())
    const [articles, setArticles] = useState({ title: [], content: [], files: [] });
    const [questions, setQuestions] = useState({ title: [], question: [], required: [], responseLength: [] });
    const articleEndRef = useRef(null)
    const questionEndRef = useRef(null)
    useEffect(() => {
        props.updateError(null)
        articleEndRef.current.scrollIntoView({ behavior: "smooth" })
    }, [articles])
    useEffect(() => {
        props.updateError(null)
        questionEndRef.current.scrollIntoView({ behavior: "smooth" })
    }, [questions])
    function RenderArticles() {
        return articles.title.map((val, i) =>
            <div key={i} className="jamescape-light-blue p-4 mb-4 mt-4">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Article Title</Form.Label>
                            <Form.Control key="articleTitle" type="text" placeholder="Title" value={val} onChange={handleTitle.bind(i)} maxLength="200" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Article Content</Form.Label>
                            <Form.Control as="textarea" rows={6} key="articleContent" placeholder="Article Content" value={articles.content[i] || ''} onChange={handleContent.bind(i)} maxLength="10000" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>File Upload</Form.Label>
                            <Form.File onChange={handleFiles.bind(i)} />
                        </Form.Group>
                        <button type='button' className="jamescape-red-button" onClick={() => deleteClick(i)}>Delete</button>
                    </Col>
                </Row>
            </div>
        );
    }
    function handleTitle(event) {
        let vals = [...articles.title];
        vals[this] = event.target.value;
        setArticles({ title: vals, content: [...articles.content], files: [...articles.files] });
    }
    function handleContent(event) {
        let vals = [...articles.content];
        vals[this] = event.target.value;
        setArticles({ title: [...articles.title], content: vals, files: [...articles.files] });
    }
    function handleFiles(event) {
        let vals = [...articles.files];
        if (event.target.files[0] !== undefined) {
            console.log(event.target.files[0])
            vals[this] = event.target.files[0];
            setArticles({ title: [...articles.title], content: [...articles.content], files: vals });
        }
    }
    const addArticle = () => {
        setArticles({ title: [...articles.title, ''], content: [...articles.content, ''], files: [...articles.files, []] })
    }
    function deleteClick(i) {
        let titles = [...articles.title];
        titles.splice(i, 1);
        let content = [...articles.content];
        content.splice(i, 1);
        let files = [...articles.files];
        files.splice(i, 1);
        setArticles({ title: titles, content: content, files: files });
    }
    function RenderQuestions() {
        return questions.title.map((val, i) =>
            <div key={i} className="jamescape-light-blue p-4 mb-4 mt-4">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Question Title</Form.Label>
                            <Form.Control key="questionTitle" type="text" placeholder="Question Title" value={val} onChange={handleQuestionTitle.bind(i)} maxLength="200" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Question Description</Form.Label>
                            <Form.Control as="textarea" rows={3} key="articleContent" placeholder="Question Description" value={questions.question[i] || ''} onChange={handleQuestion.bind(i)} maxLength="500" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Response Length - Maximum number of characters allowed in response</Form.Label>
                            <Form.Control key="questionTitle" type="text" placeholder="Response Length" value={questions.responseLength[i] || ''} onChange={handleQuestionLength.bind(i)} maxLength="9" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" label="Required" checked={questions.required[i]} onChange={handleQuestionRequired.bind(i)} />
                        </Form.Group>
                        <button type='button' className="jamescape-red-button" onClick={() => deleteQuestion(i)}>Delete</button>
                    </Col>
                </Row>
            </div>
        );
    }
    function deleteQuestion(i) {
        let titles = [...questions.title];
        titles.splice(i, 1);
        let questionContent = [...questions.question];
        questionContent.splice(i, 1);
        let required = [...questions.required];
        required.splice(i, 1);
        let responseLength = [...questions.responseLength];
        responseLength.splice(i, 1);
        setQuestions({ title: titles, question: questionContent, required: required, responseLength: responseLength });
    }
    function handleQuestionTitle(event) {
        let vals = [...questions.title];
        vals[this] = event.target.value;
        setQuestions({ title: vals, question: [...questions.question], required: [...questions.required], responseLength: [...questions.responseLength] });
    }
    function handleQuestion(event) {
        let vals = [...questions.question];
        vals[this] = event.target.value;
        setQuestions({ title: [...questions.title], question: vals, required: [...questions.required], responseLength: [...questions.responseLength] });
    }
    function handleQuestionLength(event) {
        let vals = [...questions.responseLength];
        vals[this] = event.target.value;
        setQuestions({ title: [...questions.title], question: [...questions.question], required: [...questions.required], responseLength: vals });
    }
    function handleQuestionRequired(event) {
        let vals = [...questions.required];
        vals[this] = !vals[this];
        setQuestions({ title: [...questions.title], question: [...questions.question], required: vals, responseLength: [...questions.responseLength] });
    }
    const addQuestion = () => {
        setQuestions({ title: [...questions.title, ''], question: [...questions.question, ''], required: [...questions.required, ''], responseLength: [...questions.responseLength, ''] });
    }
    const AddJob = event => {
        event.preventDefault();
        let articleArray = []
        let index = 0
        articles.title.forEach((title, index) => {
            articleArray.push({ title: title, content: articles.content[index] })
        })
        let questionArray = []
        questions.title.forEach((title, index) => {
            questionArray.push({ title: title, question: questions.question[index], required: questions.required[index], max_response_length: questions.responseLength[index] })
        })
        axios.post('/jobs/api/v1/job/create.php', {
            title: title,
            department: department,
            uploads_allowed: uploadsAllowed,
            job_alert: jobAlert,
            description: description,
            contract: contract,
            location: location,
            pay_rate: payRate,
            seniority: seniority,
            reports_to: reportsTo,
            opening_at: openingAt.getTime() / 1000,
            closing_at: closingAt.getTime() / 1000
        }).then((response) => {
            if (response.data.Message === 'OK') {
                let fd = new FormData();
                fd.append('articles', JSON.stringify(articleArray))
                fd.append('job_id', response.data.JobID)
                articleArray.map((item, i) => {
                    fd.append('File-' + i, articles.files[i])
                });
                axios.post('/jobs/api/v1/article/create.php', fd)
                    .then((response) => {
                        if (response.data.Message === "OK") {
                            index++
                            if (index === 2) {
                                history.push('/job/all')
                            }
                        } else {
                            props.updateError(response.data.Message)
                        }
                    }).catch(function (error) {
                        if (error.response) {
                            props.updateError(error.response.data)
                        } else if (error.request) {
                            props.updateError(error.request)
                        } else {
                            props.updateError(error.message)
                        }
                    });

                axios.post('/jobs/api/v1/question/create.php', {
                    questions: questionArray,
                    job_id: response.data.JobID
                }).then((response) => {
                    if (response.data.Message === "OK") {
                        index++
                        if (index === 2) {
                            history.push('/job/all')
                        }
                    } else {
                        props.updateError(response.data.Message)
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
                if (articleArray.length + questionArray.length === 0) {
                    history.push('/job/all')
                }
            } else {
                props.updateError(response.data.Message)
            }
        }).catch(function (error) {
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    const CreateJobForm = () => {
        return (
            <Form>
                <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control key="jobTitle" type="text" placeholder="Title" value={title} onChange={e => setTitle(e.target.value)} maxLength="200" required />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Job Alert</Form.Label>
                    <Form.Control as="textarea" rows={3} key="jobAlertInput" placeholder="Job Alert" value={jobAlert} onChange={e => setJobAlert(e.target.value)} maxLength="500" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Job Advert</Form.Label>
                    <Form.Control as="textarea" rows={3} key="jobDescription" placeholder="Job Advert" value={description} onChange={e => setDescription(e.target.value)} maxLength="500" required />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Max Applicant File Uploads</Form.Label>
                    <Form.Control as="select" value={uploadsAllowed} onChange={e => setUploadsAllowed(e.target.value)} key="jobMaxUploads">
                        <option value="0" selected="selected">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </Form.Control>
                </Form.Group>
                <Row>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Contract: </b></Form.Label>
                            <Form.Control key="jobContract" type="text" placeholder="Contract" value={contract} onChange={e => setContract(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Pay Rate: </b></Form.Label>
                            <Form.Control key="jobPayRate" type="text" placeholder="Pay Rate" value={payRate} onChange={e => setPayRate(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Reports to: </b></Form.Label>
                            <Form.Control key="jobReportsTo" type="text" placeholder="Reports to" value={reportsTo} onChange={e => setReportsTo(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Location: </b></Form.Label>
                            <Form.Control key="jobLocation" type="text" placeholder="Location" value={location} onChange={e => setLocation(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Seniority: </b></Form.Label>
                            <Form.Control key="jobSeniority" type="text" placeholder="Seniority" value={seniority} onChange={e => setSeniority(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Department</b></Form.Label>
                            <Form.Control key="inputDepartment" type="text" placeholder="Department" value={department} onChange={e => setDepartment(e.target.value)} maxLength="50" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <p><b>Opening At (edit using calendar):</b></p><Datetime key="openingAt" dateFormat="DD/MM/YYYY" timeFormat="H:mm" value={openingAt} onChange={moment => {
                            if (typeof moment === "string") {

                            } else {
                                setOpeningAt(moment.toDate())
                            }
                        }} />
                    </Col>
                    <Col xs={12} sm={6}>
                        <p><b>Closing At (edit using calendar):</b></p><Datetime key="closingAt" dateFormat="DD/MM/YYYY" timeFormat="H:mm" value={closingAt} onChange={moment => {
                            if (typeof moment === "string") {

                            } else {
                                setClosingAt(moment.toDate())
                            }
                        }} />
                    </Col>
                </Row>
            </Form>
        )
    }
    return (
        <>
            {CreateJobForm()}
            {RenderArticles()}
            <div className="mt-4 mb-4" ref={articleEndRef}>
                <button type="button" className="jamescape-blue-button" onClick={addArticle}>Add Article</button>
            </div>
            {RenderQuestions()}
            <div className="mt-4 mb-4" ref={questionEndRef}>
                <button type="button" className="jamescape-blue-button" onClick={AddJob}>Add Job</button>
                <button type="button" className="jamescape-blue-button ml-2" onClick={addQuestion}>Add Question</button>
                <Link to="/job/all"><button type="button" className="jamescape-red-button ml-2">Cancel</button></Link>
            </div>
        </>
    )
}