import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import BeatLoader from 'react-spinners/BeatLoader';
import { Link } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
export default function ApplicantButtons(props) {
    const [r2wStatus, setR2wStatus] = useState(props.r2wStatus)
    const [status, setStatus] = useState(props.status)
    const [show, setShow] = useState(false);
    const [sending, setSending] = useState(false);
    const [sendingR2W, setSendingR2W] = useState(false);
    const [sendingInterview, setSendingInterview] = useState(false);
    const [sendingOffer, setSendingOffer] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    function RejectApplicant() {
        setSending(true)
        axios.post('/jobs/api/v1/application/update.php', {
            id: props.applicationId,
            reject: true
        }).then((response) => {
            if (response.data.Message === "OK") {
                props.updateError(null)
                setSending(false)
                setStatus("Rejected")
                handleClose()
            } else {
                setSending(false)
                props.updateError("Error informing applicant")
            }
        }).catch(function (error) {
            setSending(true)
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    function RequestR2WForm() {
        setSendingR2W(true)
        axios.post('/jobs/api/v1/application/update.php', {
            id: props.applicationId,
            r2w: true
        }).then((response) => {
            if (response.data.Message === "OK") {
                props.updateError(null)
                setSendingR2W(false)
                setR2wStatus("Requested")
            } else {
                setSendingR2W(false)
                props.updateError("Error informing applicant")
            }
        }).catch(function (error) {
            setSendingR2W(true)
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    function InviteToInterview() {
        setSendingInterview(true)
        axios.post('/jobs/api/v1/application/update.php', {
            id: props.applicationId,
            interview: true
        }).then((response) => {
            if (response.data.Message === "OK") {
                props.updateError(null)
                setSendingInterview(false)
                setStatus("Interview Offered")
            } else {
                setSendingInterview(false)
                props.updateError("Error informing applicant")
            }
        }).catch(function (error) {
            setSendingInterview(false)
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    function OfferJob() {
        setSendingOffer(true)
        axios.post('/jobs/api/v1/application/update.php', {
            id: props.applicationId,
            offer: true
        }).then((response) => {
            if (response.data.Message === "OK") {
                props.updateError(null)
                setSendingOffer(false)
                setStatus("Offer")
            } else {
                setSendingOffer(false)
                props.updateError("Error informing applicant")
            }
        }).catch(function (error) {
            setSendingOffer(false)
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    const Buttons = () => {
        if (status === "Rejected") {
            return <button className="jamescape-transparant-button float-right ml-2">Applicant Rejected</button>
        } else if (status === "R2W Requested") {
            return (
                <>
                    <button type="button" className="jamescape-blue-button float-right ml-2 mb-3" onClick={() => InviteToInterview()}>{sendingInterview ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Invite to Interview"}</button>
                    <button type="button" className="jamescape-red-button float-right ml-2 mb-3" onClick={() => handleShow()}>Reject Applicant</button>
                    <p className="jamescape-transparant-button float-right ml-2 mb-2 mt-2">R2W Form Requested</p>
                </>
            )
        } else if (status === "Interview Offered") {
            return (
                <>
                    <button type="button" className="jamescape-blue-button float-right ml-2 mb-3" onClick={() => OfferJob()}>{sendingOffer ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Offer Job"}</button>
                    <button type="button" className="jamescape-red-button float-right ml-2 mb-3" onClick={() => handleShow()}>Reject Applicant</button>
                    <p className="jamescape-transparant-button float-right mb-2 mt-2">Invited To Interview</p>
                </>
            )
        } else if (status === "Offer") {
            return (
                <>
                    <button type="button" className="jamescape-red-button float-right ml-2 mb-3" onClick={() => handleShow()}>Reject Applicant</button>
                    <p className="jamescape-transparant-button float-right ml-2 mb-2 mt-2">Job Offered</p>
                </>
            )
        } else if (status === "Accepted") {
            return (
                <>
                    <p className="jamescape-transparant-button float-right ml-2 mb-2 mt-2">Job Accepted</p>
                </>
            )
        } else if (status === "Withdrawn") {
            return (
                <>
                    <p className="jamescape-transparant-button float-right ml-2 mb-2 mt-2">Applicant Withdrew</p>
                </>
            )
        } else {
            return (
                <>
                    <button type="button" className="jamescape-blue-button float-right ml-2 mb-3" onClick={() => InviteToInterview()}>{sendingInterview ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Invite to Interview"}</button>
                    <button type="button" className="jamescape-red-button float-right ml-2 mb-3" onClick={() => handleShow()}>Reject Applicant</button>
                </>
            )
        }
    }
    const R2WButton = () => {
        if (r2wStatus === 'Submitted') {
            return (
                <Link to={"/right-to-work/" + props.applicantId}><button type="button" className="jamescape-blue-button float-right ml-2 mb-3">View R2W Form</button></Link>
            )
        } else if (status !== "Rejected" && status !== "Withdrawn" && status !== "Requested" && r2wStatus !== "Requested") {
            return (
                <button type="button" className="jamescape-blue-button float-right ml-2 mb-3" onClick={() => RequestR2WForm()}>{sendingR2W ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Request R2W Form"}</button>
            )
        } else if (r2wStatus === 'Requested') {
            return (
                <p className="jamescape-transparant-button float-right ml-2 mb-2 mt-2">R2W Requested</p>
            )
        } else {
            return null
        }
    }
    return (
        <>
            <ButtonGroup>
                <R2WButton />
                <Buttons />
            </ButtonGroup>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reject Applicant?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to reject applicant {props.firstName} {props.lastName}?</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="jamescape-blue-button" onClick={handleClose}>Cancel</button>
                    <button type="button" className="jamescape-red-button" onClick={() => { RejectApplicant() }}>{sending ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Reject Applicant"}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}