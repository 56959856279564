import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Cookies from 'js-cookie';
import BeatLoader from 'react-spinners/BeatLoader';
import Container from 'react-bootstrap/esm/Container';
import Datetime from 'react-datetime';
import { Alert } from 'react-bootstrap/Alert';
import history from '../utilities/History'
import Modal from 'react-bootstrap/Modal'
import { useDropzone } from 'react-dropzone';
import Article from './Article';
export default function EditJob(props) {
    const [title, setTitle] = useState("")
    const [department, setDepartment] = useState("")
    const [description, setDescription] = useState("")
    const [uploadsAllowed, setUploadsAllowed] = useState("")
    const [jobAlert, setJobAlert] = useState("")
    const [contract, setContract] = useState("")
    const [location, setLocation] = useState("")
    const [payRate, setPayRate] = useState("")
    const [seniority, setSeniority] = useState("")
    const [reportsTo, setReportsTo] = useState("")
    const [openingAt, setOpeningAt] = useState(new Date())
    const [closingAt, setClosingAt] = useState(new Date())
    const [modalTitle, setModalTitle] = useState('')
    const [modalBody, setModalBody] = useState('')
    const [modalDeleteDestination, setModalDeleteDestination] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [articles, setArticles] = useState({ title: [], content: [], files: [], id: [] });
    const [questions, setQuestions] = useState({ title: [], question: [], required: [], max_response_length: [], id: [] });
    const articleEndRef = useRef(null)
    useEffect(() => {
        props.updateError(null)
        articleEndRef.current.scrollIntoView({ behavior: "smooth" })
    }, [articles])

    const handleClose = () => setModalOpen(false);
    function handleShow(title, body, destination) {
        setModalOpen(true);
        setModalTitle(title)
        setModalBody(body)
        setModalDeleteDestination(destination)
    }

    useEffect(() => {
        axios.get('/jobs/api/v1/job/get.php?id=' + props.match.params.id)
            .then((response) => {
                if (response.data.Message === 'OK') {
                    setTitle(response.data.Jobs.title)
                    setDepartment(response.data.Jobs.department)
                    setDescription(response.data.Jobs.description)
                    setUploadsAllowed(response.data.Jobs.uploads_allowed)
                    setJobAlert(response.data.Jobs.job_alert)
                    setContract(response.data.Jobs.contract)
                    setLocation(response.data.Jobs.location)
                    setPayRate(response.data.Jobs.pay_rate)
                    setSeniority(response.data.Jobs.seniority)
                    setReportsTo(response.data.Jobs.reports_to)
                    setOpeningAt(new Date(response.data.Jobs.opening_at * 1000))
                    setClosingAt(new Date(response.data.Jobs.closing_at * 1000))
                    if (response.data.Jobs.Articles !== null) {
                        let updatedState = { title: [], content: [], files: [], id: [] }
                        response.data.Jobs.Articles.forEach((Article) => {
                            updatedState = { title: [...updatedState.title, Article.title], content: [...updatedState.content, Article.content], files: [...updatedState.files, Article.Files], id: [...updatedState.id, Article.id] }
                        })
                        setArticles(updatedState)
                    }
                    axios.get('/jobs/api/v1/question/get.php?job_id=' + props.match.params.id)
                        .then((response) => {
                            if (response.data.Message === 'OK') {
                                if (response.data.Questions !== null) {
                                    let updatedQuestions = { title: [], question: [], required: [], max_response_length: [], id: [] }
                                    response.data.Questions.forEach((Question) => {
                                        updatedQuestions = { title: [...updatedQuestions.title, Question.title], question: [...updatedQuestions.question, Question.question], required: [...updatedQuestions.required, Question.required], max_response_length: [...updatedQuestions.max_response_length, Question.max_response_length], id: [...updatedQuestions.id, Question.id] }
                                    })
                                    setQuestions(updatedQuestions)
                                }
                            } else {
                                props.updateError(response.data.Message)
                            }
                        }).catch(function (error) {
                            if (error.response) {
                                props.updateError(error.response.data.Message)
                            } else if (error.request) {
                                props.updateError(error.request)
                            } else {
                                props.updateError(error.message)
                            }
                        });
                } else {
                    props.updateError(response.data.Message)
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
    }, [])
    function RenderArticles() {
        return articles.title.map((val, i) =>
            <div key={i} className="jamescape-light-blue p-4 mb-4 mt-4">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Article Title</Form.Label>
                            <Form.Control key="articleTitle" type="text" placeholder="Title" value={val} onChange={handleTitle.bind(i)} maxLength="200" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Article Content</Form.Label>
                            <Form.Control as="textarea" rows={6} key="articleContent" placeholder="Article Content" value={articles.content[i] || ''} onChange={handleContent.bind(i)} maxLength="10000" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>File Upload</Form.Label>
                            <Form.File onChange={handleFiles.bind(i)} />
                            <Form.Label>{articles.files[i].name ? ("Uploaded File: " + articles.files[i].name) : (null)}</Form.Label>
                        </Form.Group>
                        <button type='button' className="jamescape-red-button" onClick={() => handleShow('Delete Article ' + articles.title[i], 'Are you sure you want to delete this article? - This cannot be undone', i)}>Delete</button>
                    </Col>
                </Row>
            </div>
        );
    }
    function handleTitle(event) {
        let vals = [...articles.title];
        vals[this] = event.target.value;
        setArticles({ title: vals, content: [...articles.content], files: [...articles.files], id: [...articles.id] });
    }
    function handleContent(event) {
        let vals = [...articles.content];
        vals[this] = event.target.value;
        setArticles({ title: [...articles.title], content: vals, files: [...articles.files], id: [...articles.id] });
    }
    function handleFiles(event) {
        let vals = [...articles.files];
        if (event.target.files[0] !== undefined) {
            vals[this] = event.target.files[0];
            setArticles({ title: [...articles.title], content: [...articles.content], files: vals, id: [...articles.id] });
        }
    }
    const addArticle = () => {
        setArticles({ title: [...articles.title, ''], content: [...articles.content, ''], files: [...articles.files, []], id: [...articles.id, ''] })
    }
    function deleteArticle(i) {
        let titles = [...articles.title];
        titles.splice(i, 1);
        let content = [...articles.content];
        content.splice(i, 1);
        let files = [...articles.files];
        files.splice(i, 1);
        let ids = [...articles.id];
        ids.splice(i, 1);
        setArticles({ title: titles, content: content, files: files, id: ids });
    }
    function handleDelete() {
        if (modalTitle.substring(7, 8) === 'A') {
            if (articles.id[modalDeleteDestination] === '') {
                deleteArticle(modalDeleteDestination)
                handleClose()
            } else {
                axios.post('/jobs/api/v1/article/delete.php', {
                    article_id: articles.id[modalDeleteDestination]
                }).then((response) => {
                    if (response.data.Message === "OK") {
                        deleteArticle(modalDeleteDestination)
                        handleClose()
                    } else {
                        props.updateError("Unable to delete Article")
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
            }
        } else {
            if (questions.id[modalDeleteDestination] === '') {
                deleteQuestion(modalDeleteDestination)
                handleClose()
            } else {
                axios.post('/jobs/api/v1/question/delete.php', {
                    question_id: questions.id[modalDeleteDestination]
                }).then((response) => {
                    if (response.data.Message === "OK") {
                        deleteQuestion(modalDeleteDestination)
                        handleClose()
                    } else {
                        props.updateError("Unable to delete question")
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
            }
        }
    }
    function RenderQuestions() {
        return questions.title.map((val, i) =>
            <div key={i} className="jamescape-light-blue p-4 mb-4 mt-4">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Question Title</Form.Label>
                            <Form.Control key="questionTitle" type="text" placeholder="Question Title" value={val} onChange={handleQuestionTitle.bind(i)} maxLength="200" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Question Description</Form.Label>
                            <Form.Control as="textarea" rows={3} key="articleContent" placeholder="Question Description" value={questions.question[i] || ''} onChange={handleQuestion.bind(i)} maxLength="500" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Response Length - Maximum number of characters allowed in response</Form.Label>
                            <Form.Control key="questionTitle" type="text" placeholder="Response Length" value={questions.max_response_length[i] || ''} onChange={handleQuestionLength.bind(i)} maxLength="9" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" label="Required" checked={questions.required[i]} onChange={handleQuestionRequired.bind(i)} />
                        </Form.Group>
                        <button type='button' className="jamescape-red-button" onClick={() => handleShow('Delete Question ' + questions.title[i], 'Are you sure you want to delete this Question? - This cannot be undone', i)}>Delete</button>
                    </Col>
                </Row>
            </div>
        );
    }
    function deleteQuestion(i) {
        let titles = [...questions.title];
        titles.splice(i, 1);
        let questionContent = [...questions.question];
        questionContent.splice(i, 1);
        let required = [...questions.required];
        required.splice(i, 1);
        let max_response_length = [...questions.max_response_length];
        max_response_length.splice(i, 1);
        let id = [...questions.id]
        id.splice(i, 1)
        setQuestions({ title: titles, question: questionContent, required: required, max_response_length: max_response_length, id: id });
    }
    function handleQuestionTitle(event) {
        let vals = [...questions.title];
        vals[this] = event.target.value;
        setQuestions({ title: vals, question: [...questions.question], required: [...questions.required], max_response_length: [...questions.max_response_length], id: [...questions.id] });
    }
    function handleQuestion(event) {
        let vals = [...questions.question];
        vals[this] = event.target.value;
        setQuestions({ title: [...questions.title], question: vals, required: [...questions.required], max_response_length: [...questions.max_response_length], id: [...questions.id] });
    }
    function handleQuestionLength(event) {
        let vals = [...questions.max_response_length];
        vals[this] = event.target.value;
        setQuestions({ title: [...questions.title], question: [...questions.question], required: [...questions.required], max_response_length: vals, id: [...questions.id] });
    }
    function handleQuestionRequired(event) {
        let vals = [...questions.required];
        vals[this] = !vals[this];
        setQuestions({ title: [...questions.title], question: [...questions.question], required: vals, max_response_length: [...questions.max_response_length], id: [...questions.id] });
    }
    const addQuestion = () => {
        setQuestions({ title: [...questions.title, ''], question: [...questions.question, ''], required: [...questions.required, ''], max_response_length: [...questions.max_response_length, ''], id: [...questions.id, ''] });
    }
    const UpdateJob = event => {
        event.preventDefault();
        let newArticleArray = []
        let articleArray = []
        articles.id.forEach((id, index) => {
            if (id === '') {
                newArticleArray.push({ title: articles.title[index], content: articles.content[index], file: articles.files[index] })
            } else {
                articleArray.push({ title: articles.title[index], content: articles.content[index], file: articles.files[index], id: id })
            }
        })
        let articleArrayLength = newArticleArray.length
        articleArray.map((file) => {
            if (file.file !== null || undefined) {
            }
        })
        if (articleArray !== undefined) {
            articleArrayLength += articleArray.length
        }
        let newQuestionArray = []
        let questionArray = []
        questions.id.forEach((id, index) => {
            if (id === '') {
                newQuestionArray.push({ title: questions.title[index], question: questions.question[index], required: questions.required[index], max_response_length: questions.max_response_length[index] })
            } else {
                questionArray.push({ title: questions.title[index], question: questions.question[index], required: questions.required[index], max_response_length: questions.max_response_length[index], id: id })
            }
        })
        let questionArrayLength = newQuestionArray.length
        if (questionArray !== undefined) {
            questionArrayLength += questionArray.length
        }
        if (uploadsAllowed === null) {
            setUploadsAllowed(-1)
        }
        axios.post('/jobs/api/v1/job/update.php', {
            id: props.match.params.id,
            title: title,
            department: department,
            uploads_allowed: uploadsAllowed,
            job_alert: jobAlert,
            description: description,
            contract: contract,
            location: location,
            pay_rate: payRate,
            seniority: seniority,
            reports_to: reportsTo,
            opening_at: openingAt.getTime() / 1000,
            closing_at: closingAt.getTime() / 1000
        }).then((response) => {
            if (response.data.Message === 'OK') {
                if ((articles.id != undefined && articles.id.length === 0) && (questions.id != undefined && questions.id.length === 0)) {
                    history.push('/job/all')
                }
                let index = 0;
                let fd = new FormData();
                fd.append('articles', JSON.stringify(articleArray))
                articleArray.map((item, i) => {
                    if (articleArray != undefined) {
                        console.log(articleArray[i].file)
                        if (articleArray[i].file !== false) {
                            fd.append('File-' + i, articleArray[i].file)
                        }
                    }
                });
                axios.post('/jobs/api/v1/article/update.php', fd)
                    .then((response) => {
                        if (response.data.Message === "OK") {
                            index++
                            if (index === 4) {
                                history.push('/job/all')
                            }
                        } else {
                            props.updateError(response.data.Message)
                        }
                    }).catch(function (error) {
                        if (error.response) {
                            props.updateError(error.response.data)
                        } else if (error.request) {
                            props.updateError(error.request)
                        } else {
                            props.updateError(error.message)
                        }
                    });
                let fd2 = new FormData();
                fd2.append('articles', JSON.stringify(newArticleArray))
                fd2.append('job_id', props.match.params.id)
                newArticleArray.map((item, i) => {
                    if (newArticleArray.files != undefined) {
                        fd2.append('File-' + i, newArticleArray.files[i])
                    }
                });
                axios.post('/jobs/api/v1/article/create.php', fd2)
                    .then((response) => {
                        if (response.data.Message === "OK") {
                            index++
                            if (index === 4) {
                                history.push('/job/all')
                            }
                        } else {
                            props.updateError(response.data.Message)
                        }
                    }).catch(function (error) {
                        if (error.response) {
                            props.updateError(error.response.data)
                        } else if (error.request) {
                            props.updateError(error.request)
                        } else {
                            props.updateError(error.message)
                        }
                    });
                axios.post('/jobs/api/v1/question/update.php', {
                    questions: questionArray
                }).then((response) => {
                    if (response.data.Message === "OK") {
                        index++
                        if (index === 4) {
                            history.push('/job/all')
                        }
                    } else {
                        props.updateError(response.data.Message)
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
                axios.post('/jobs/api/v1/question/create.php', {
                    questions: newQuestionArray,
                    job_id: props.match.params.id
                }).then((response) => {
                    if (response.data.Message === "OK") {
                        index++
                        if (index === 4) {
                            history.push('/job/all')
                        }
                    } else {
                        props.updateError(response.data.Message)
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
            } else {
                props.updateError(response.data.Message)
            }
        }).catch(function (error) {
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    const EditJobForm = () => {
        return (
            <Form>
                <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control key="jobTitle" type="text" placeholder="Title" value={title} onChange={e => setTitle(e.target.value)} maxLength="200" required />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Job Alert</Form.Label>
                    <Form.Control as="textarea" rows={3} key="jobAlertInput" placeholder="Job Alert" value={jobAlert} onChange={e => setJobAlert(e.target.value)} maxLength="500" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Job Advert</Form.Label>
                    <Form.Control as="textarea" rows={3} key="jobDescription" placeholder="Job Alert" value={description} onChange={e => setDescription(e.target.value)} maxLength="500" required />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Max Applicant File Uploads</Form.Label>
                    <Form.Control as="select" value={uploadsAllowed} onChange={e => setUploadsAllowed(e.target.value)} key="jobMaxUploads">
                        <option value="0" selected="selected">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </Form.Control>
                </Form.Group>
                <Row>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Contract: </b></Form.Label>
                            <Form.Control key="jobContract" type="text" placeholder="Contract" value={contract} onChange={e => setContract(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Pay Rate: </b></Form.Label>
                            <Form.Control key="jobPayRate" type="text" placeholder="Pay Rate" value={payRate} onChange={e => setPayRate(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Reports to: </b></Form.Label>
                            <Form.Control key="jobReportsTo" type="text" placeholder="Reports to" value={reportsTo} onChange={e => setReportsTo(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Location: </b></Form.Label>
                            <Form.Control key="jobLocation" type="text" placeholder="Location" value={location} onChange={e => setLocation(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Seniority: </b></Form.Label>
                            <Form.Control key="jobSeniority" type="text" placeholder="Seniority" value={seniority} onChange={e => setSeniority(e.target.value)} maxLength="200" required />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Form.Group>
                            <Form.Label><b>Department</b></Form.Label>
                            <Form.Control key="inputDepartment" type="text" placeholder="Department" value={department} onChange={e => setDepartment(e.target.value)} maxLength="50" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <p><b>Opening At (edit using calendar):</b></p><Datetime key="openingAt" dateFormat="DD/MM/YYYY" timeFormat="H:mm" value={openingAt} onChange={moment => {
                            if (typeof moment === "string") {

                            } else {
                                setOpeningAt(moment.toDate())
                            }
                        }} />
                    </Col>
                    <Col xs={12} sm={6}>
                        <p><b>Closing At (edit using calendar):</b></p><Datetime key="closingAt" dateFormat="DD/MM/YYYY" timeFormat="H:mm" value={closingAt} onChange={moment => {
                            if (typeof moment === "string") {

                            } else {
                                setClosingAt(moment.toDate())
                            }
                        }} />
                    </Col>
                </Row>
            </Form>
        )
    }
    return (
        <>
            <button type="button" className="jamescape-blue-button mb-4 mt-4" onClick={history.goBack}>Back</button>
            {EditJobForm()}
            {RenderQuestions()}
            <button type="button" className="jamescape-blue-button mt-4" onClick={addQuestion}>Add Question</button>
            {RenderArticles()}
            <div className="mt-4 mb-4" ref={articleEndRef}>
                <button type="button" className="jamescape-blue-button" onClick={UpdateJob}>Save</button>
                <button type="button" className="jamescape-blue-button ml-2" onClick={addArticle}>Add Article</button>
                <Link to="/job/all"><button type="button" className="jamescape-red-button ml-2">Cancel</button></Link>
            </div>
            <DeleteModal />
        </>
    )
    function DeleteModal() {
        return (
            <>
                <Modal show={modalOpen} onHide={handleClose} className="jamescape-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalBody}</Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick={handleClose} className="jamescape-blue-button">Cancel</button>
                        <button type="button" onClick={() => handleDelete()} className="jamescape-red-button">Delete</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}