import React, { useEffect } from 'react';
import {
    NavLink,
    Link
} from "react-router-dom"
import axios from 'axios';
import Cookies from 'js-cookie';
import history from '../utilities/History';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import bannerAndLogo from '../resources/bannerandlogo.svg';

export default function RenderNavbar(props) {
    useEffect(() => {
        let cookie = Cookies.get('Session')
        if (cookie === 'Staff') {
            props.setStatus("staff")
        } else if (cookie === 'Applicant') {
            props.setStatus("applicant")
        } else {
            props.setStatus(false)
        }
    }, [props.status])
    function LoggedInNav() {
        return (
            <>
                <Navbar expand="lg">
                    <Navbar.Brand>
                        <Link to={'/'}>
                            <img
                                alt="Jamescape Logo"
                                src={bannerAndLogo}
                                className="d-inline-block align-top jamescape-logo-brand"
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-dark" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-link-margin pr-3">
                            <NavLink to='/' exact activeClassName="active" className="nav-link">Home</NavLink>
                            <NavLink to='/job/' activeClassName="active" className="nav-link">Jobs</NavLink>
                            <NavLink to='/applicant/applications' activeClassName="active" className="nav-link">My Applications</NavLink>
                            <NavLink to='/alerts/' activeClassName="active" className="nav-link">Alerts</NavLink>
                            <Nav.Link onClick={Logout} className="nav-link">Log out</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </>
        )
    }
    function ApplicantNav() {
        return (
            <>
                <Navbar expand="lg">
                    <Navbar.Brand>
                        <Link to={'/'}>
                            <img
                                alt="Jamescape Logo"
                                src={bannerAndLogo}
                                className="d-inline-block align-top jamescape-logo-brand"
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-dark" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-link-margin">
                            <NavLink to='/' exact activeClassName="active" className="nav-link">Home</NavLink>
                            <NavLink to='/applicant/applications' activeClassName="active" className="nav-link">My Applications</NavLink>
                            <Nav.Link onClick={Logout} className="nav-link">Log out</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </>
        )
    }
    function Logout() {
        axios.get('/jobs/api/v1/auth/logout.php').then((response) => {
            if (response.data.Message === 'OK') {
                if (Cookies.get("Session") !== undefined) {
                    Cookies.remove("Session")
                }
                history.push('/')
                props.setStatus(false)
            }
        }).catch(function (error) {
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    return (
        <>
            {props.status === "staff" ? <LoggedInNav /> : null}
            {props.status === "applicant" ? <ApplicantNav /> : null}
            {props.status !== "applicant" && props.status !== "staff" ? <>
                <Navbar expand="lg">
                    <Navbar.Brand>
                        <Link to={'/'}>
                            <img
                                alt="Jamescape Logo"
                                src={bannerAndLogo}
                                className="d-inline-block align-top jamescape-logo-brand"
                            />
                        </Link>
                    </Navbar.Brand>
                </Navbar>
            </> : null}
        </>
    )
}