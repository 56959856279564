import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Datetime from 'react-datetime';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import BeatLoader from 'react-spinners/BeatLoader';
import history from '../utilities/History'
export default function RightToWorkForm(props) {
    const [formProgress, setFormProgress] = useState(0)
    const [validation, setValidation] = useState(null)
    const [firstName, setFirstName] = useState("")
    const [middleNames, setMiddleNames] = useState("")
    const [lastName, setLastName] = useState("")
    const [checklist, setChecklist] = useState(20)
    const [checklistB1, setChecklistB1] = useState(0)
    const [checklistB2, setChecklistB2] = useState(0)
    const [differentLegalName, setDifferentLegalName] = useState(null)
    const [differentLegalNameExplanation, setDifferentLegalNameExplanation] = useState(null)
    const [timeLimited, setTimeLimited] = useState(null)
    const [timeLimitedDate, setTimeLimitedDate] = useState(null)
    const [student, setStudent] = useState(null)
    const [nin, setNin] = useState(3)
    const [ninNumber, setNinNumber] = useState("")
    const [noNinReason, setNoNinReason] = useState(" ")
    const [files, setFiles] = useState([])
    const [submitting, setSubmitting] = useState(false)
    useEffect(() => {
        axios.get('/jobs/api/v1/r2w/get.php?myR2w')
            .then((response) => {
                if (response.data.Message === "OK") {
                    setFirstName(response.data.Form.first_name)
                    setMiddleNames(response.data.Form.other_name)
                    setLastName(response.data.Form.last_name)
                    setDifferentLegalName(response.data.Form.legal_name_different)
                    setDifferentLegalNameExplanation(response.data.Form.legal_name_different_reason)
                    setTimeLimited(response.data.Form.time_limited_permission)
                    if (response.data.Form.right_to_work_expiration !== null) {
                        setTimeLimitedDate(new Date(+response.data.Form.right_to_work_expiration))
                    }
                    setStudent(response.data.Form.student)
                    setNin(response.data.Form.nin)
                    setNinNumber(response.data.Form.nin_number)
                    setNoNinReason(response.data.Form.nin_reason)
                } else if (response.data.Message = "Not Requested") {
                    history.push('/')
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
    }, [])
    function Validate() {
        switch (formProgress) {
            case 0:
                if (firstName == null || firstName.length === 0 && firstName.length < 101) {
                    setValidation("Invalid First Name")
                } else if (lastName == null || lastName.length === 0 && lastName.length < 101) {
                    setValidation("Invalid Last Name")
                } else if (middleNames != null && middleNames.length > 101) {
                    setValidation("Invalid Middle Names")
                }
                else {
                    setValidation(false)
                    Update()
                }
                break;
            case 1:
                if (differentLegalName === null) {
                    setValidation("You must answer this question")
                } else if (differentLegalName === 0) {
                    Update()
                } else if (differentLegalName === 1) {
                    if (differentLegalNameExplanation === null) {
                        setValidation("You must answer this question")
                    } else {
                        Update()
                    }
                }
                break;
            case 2:
                if (timeLimited === null) {
                    setValidation("You must answer this question")
                } else if (timeLimited === 0) {
                    Update()
                } else if (timeLimited === 1) {
                    if (timeLimitedDate === null) {
                        setValidation("You must answer this question")
                    } else if (timeLimitedDate.getTime() < Date.now()) {
                        setValidation("Date cannot be in history")
                    } else {
                        Update()
                    }
                }
                break;
            case 3:
                if (student === null) {
                    setValidation("You must answer this question")
                } else {
                    Update()
                }
                break;
            case 4:
                if (nin === 3) {
                    setValidation("You must answer this question")
                } else if (nin === 1) {
                    if (ninNumber.match(/^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{1}$/i)) {
                        Update()
                    } else {
                        setValidation("Invalid NI Number")
                    }
                } else {
                    if (noNinReason === null) {
                        setValidation("You must answer this question")
                    } else {
                        Update()
                    }
                }

                break;
        }
    }
    function Update() {
        setFormProgress(formProgress + 1)
        setValidation(null)
        let date = null
        if (timeLimitedDate !== 0 && timeLimitedDate !== null) {
            date = timeLimitedDate.getTime()
        }
        axios.post('/jobs/api/v1/r2w/update.php', {
            firstName: firstName,
            otherName: middleNames,
            lastName: lastName,
            differentLegalName: differentLegalName,
            differentLegalNameExplanation: differentLegalNameExplanation,
            timeLimited: timeLimited,
            timeLimitedDate: date,
            student: student,
            nin: nin,
            ninNumber: ninNumber,
            ninReason: noNinReason
        }).then((response) => {
            if (response.data.Message === "OK") {
                setFormProgress(formProgress + 1)
            }
        }).catch(function (error) {
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    function Submit() {
        setValidation(null)
        setSubmitting(true)
        let fd = new FormData();
        fd.append('firstName', firstName)
        fd.append('otherName', middleNames)
        fd.append('lastName', lastName)
        fd.append('differentLegalName', differentLegalName)
        fd.append('differentLegalNameExplanation', differentLegalNameExplanation)
        fd.append('timeLimited', timeLimited)
        if (timeLimitedDate === 0 || timeLimitedDate === null) {
            fd.append('timeLimitedDate', null)
        } else {
            fd.append('timeLimitedDate', timeLimitedDate.getTime())
        }
        fd.append('student', student)
        fd.append('nin', nin)
        fd.append('ninNumber', ninNumber)
        fd.append('ninReason', noNinReason)
        fd.append('listA', checklist)
        fd.append('listB1', checklistB1)
        fd.append('listB2', checklistB2)
        files.forEach((file) => {
            fd.append('File[]', file);
        });
        axios.post('/jobs/api/v1/r2w/submit.php', fd).then((response) => {
            if (response.data.Message === "OK") {
                setSubmitting(false)
                history.push('/')
            } else {
                setSubmitting(false)
                setValidation(response.data.Message)
            }
        }).catch(function (error) {
            setSubmitting(false)
            if (error.response) {
                props.updateError(error.response.data)
            } else if (error.request) {
                props.updateError(error.request)
            } else {
                props.updateError(error.message)
            }
        });
    }
    const Dropzone = () => {
        let filesAllowed = 7 - files.length

        const {
            acceptedFiles,
            getRootProps,
            getInputProps
        } = useDropzone({
            maxFiles: filesAllowed,
            accept: '.png,.jpeg,.jpg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            onDrop: acceptedFiles => {
                if (filesAllowed !== 0) {
                    setFiles(files => files.concat(acceptedFiles))
                } else if (filesAllowed < 0) {
                    setFiles([])
                }
            }
        });
        function RemoveFile(i) {
            setFiles(files => files.filter(file => file.path !== files[i].path))
        }
        function AcceptedFileItems() {
            return files.map((file, i) => (
                <li key={file.path}>
                    {file.path} - <button type="button" className="jamescape-red-button" onClick={() => RemoveFile(i)}>Remove File</button>
                </li>
            ));
        }
        return (
            <section className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                    <em>({filesAllowed} files is the maximum number of files you can upload)</em>
                </div>
                <aside>
                    <h4>Files</h4>
                    <ul><AcceptedFileItems /></ul>
                </aside>
            </section>
        );
    }
    const FullLegalName = () => {
        return (
            <>
                <Row>
                    <Col md={true}>
                        <h4>Full Legal Name</h4>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={true}>
                        <Form.Group>
                            <Form.Label>Middle Name(s)</Form.Label>
                            <Form.Control type="text" placeholder="Middle Name(s)" value={middleNames} onChange={(e) => setMiddleNames(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={true}>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }
    const LegalNameDifferent = () => {
        return (
            <>
                <Row>
                    <Col>
                        <p>Is your full legal name different to the name on the document you will be using to prove your Right to Work in the UK?</p>
                        <Form.Check type='radio' inline label="Yes" onChange={() => setDifferentLegalName(1)} checked={differentLegalName === 1} />
                        <Form.Check type='radio' inline label="No" onChange={() => setDifferentLegalName(0)} checked={differentLegalName === 0} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {differentLegalName === 1 ? <Form.Group className="mt-4"><Form.Label>Give details as to why</Form.Label><Form.Control as="textarea" rows={3} onChange={(e) => setDifferentLegalNameExplanation(e.target.value)} value={differentLegalNameExplanation} /><p><i>Evidence will be required to corroborate this, for example: if you have changed your name by
                            deed poll, you must submit this; if you have recently married, you must submit a copy of your marriage certificate. You can submit this at the end of the form.</i></p></Form.Group> : null}

                    </Col>
                </Row>
            </>
        )
    }
    const TimeLimitedPermission = () => {
        return (
            <>
                <Row>
                    <Col>
                        <p>Do your documents give you time-limited permission to work in the UK?</p>
                        <Form.Check type='radio' inline label="Yes" onChange={() => setTimeLimited(1)} checked={timeLimited === 1} />
                        <Form.Check type='radio' inline label="No" onChange={() => setTimeLimited(0)} checked={timeLimited === 0} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {timeLimited === 1 ? <div className="mt-4"><p>When does your Right to Work in the UK expire?</p><Datetime key="closingAt" dateFormat="DD/MM/YYYY" closeOnSelect={true} closeOnClickOutside={false} timeFormat={false} value={timeLimitedDate} onChange={moment => {
                            if (typeof moment === "string") {

                            } else {
                                setTimeLimitedDate(moment.toDate())
                            }
                        }} /></div> : null}
                    </Col>
                </Row>
            </>
        )
    }
    const AreYouAStudent = () => {
        return (
            <>
                <Row>
                    <Col>
                        <p>Are you a student <b>and</b> only permitted to work during term times?</p>
                        <Form.Check type='radio' inline label="Yes" onChange={() => setStudent(1)} checked={student === 1} />
                        <Form.Check type='radio' inline label="No" onChange={() => setStudent(0)} checked={student === 0} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {student === 1 ? <p><i>You are required to provide evidence to corroborate this, evidence of your academic term and vacation times must be uploaded at the end of the form.</i></p> : null}
                    </Col>
                </Row>
            </>
        )
    }
    const NIN = () => {
        return (
            <>
                <Row>
                    <Col>
                        <p>Do you have a National Insurance Number (NIN)?</p>
                        <Form.Check type='radio' inline label="Yes" onChange={() => setNin(1)} checked={nin === 1} />
                        <Form.Check type='radio' inline label="No" onChange={() => setNin(0)} checked={nin === 0} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {nin === 1 ? <Form.Group className="mt-4"><Form.Label>Your NIN Number</Form.Label><Form.Control onChange={(e) => setNinNumber(e.target.value)} value={ninNumber} maxLength={9} /></Form.Group> : null}
                        {nin === 0 ? <Form.Group className="mt-4"><Form.Label>Give details as to why</Form.Label><Form.Control as="textarea" rows={3} onChange={(e) => setNoNinReason(e.target.value)} value={noNinReason} /></Form.Group> : null}
                    </Col>
                </Row>

            </>
        )
    }
    const RightToWorkDocument = () => {
        return (
            <>
                <div>
                    <h1>Choose which method to validate Right to Work, either one Item from List A, or one item from both List B Group 1 and Group 2</h1>
                    <h1>List A</h1>
                    <Row>
                        <Col md={10}>
                            <p>A passport showing the holder, or a person named in the passport as the child of the holder, is a British citizen or a citizen of the UK and Colonies having the right of abode in the UK.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 1" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(1) }} checked={checklist === 1} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A passport or passport card (current or expired) showing that the holder is a national of the Republic of Ireland.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(2) }} checked={checklist === 2} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A <b>current</b> document issued by the Home Office to a family member of an EEA or Swiss citizen, and which indicates that the holder is permitted to stay in the United Kingdom indefinitely.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(3) }} checked={checklist === 3} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A document issued by the Bailiwick of Jersey, the Bailiwick of Guernsey or the Isle of Man, which has been verified as valid by the Home Office Employer Checking Service, showing that the holder has been granted unlimited leave to enter or remain under Appendix EU to the Jersey Immigration Rules, Appendix EU to the Immigration (Bailiwick of Guernsey) Rules 2008 or Appendix EU to the Isle of Man Immigration Rules</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(4) }} checked={checklist === 4} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A <b>current</b> Biometric Immigration Document (Biometric Residence Permit) issued by the Home Office to the holder indicating that the person named is allowed to stay indefinitely in the UK, or has no time limit on their stay in the UK.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(5) }} checked={checklist === 5} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A <b>current</b> passport endorsed to show that the holder is exempt from immigration control, is allowed to stay indefinitely in the UK, has the right of abode in the UK, or has no time limit on their stay in the UK.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(6) }} checked={checklist === 6} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A <b>current</b> Immigration Status Document issued by the Home Office to the holder with an endorsement indicating that the named person is allowed to stay indefinitely in the UK or has no time limit on their stay in the UK, together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(7) }} checked={checklist === 7} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A birth or adoption certificate issued in the UK, <b>together with</b> an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(8) }} checked={checklist === 8} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A birth or adoption certificate issued in the Channel Islands, the Isle of Man or Ireland, <b>together with</b> an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(9) }} checked={checklist === 9} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A certificate of registration or naturalisation as a British citizen, <b>together with</b> an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => { setChecklistB1(0); setChecklistB2(0); setChecklist(10) }} checked={checklist === 10} />
                        </Col>
                    </Row>
                </div>
                <div>
                    <h1>List B Group 1</h1>
                    <Row>
                        <Col md={10}>
                            <p>A <b>current</b> passport endorsed to show that the holder is allowed to stay in the UK and is currently allowed to do the type of work in question.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 1" onChange={() => {
                                if (checklistB2 !== 1) {
                                    setChecklistB1(1); setChecklist(20)
                                }
                            }} checked={checklistB1 === 1} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A <b>current</b> Biometric Immigration Document (Biometric Residence Permit) issued by the Home Office to the holder which indicates that the named person can currently stay in the UK and is allowed to do the work in question.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB2 !== 2) {
                                    setChecklistB1(2); setChecklist(20)
                                }
                            }} checked={checklistB1 === 2} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A <b>current</b> document issued by the Home Office to a family member of an EEA or Swiss citizen, and which indicates that the holder is permitted to stay in the United Kingdom for a time limited period and to do the type of work in question.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB2 !== 3) {
                                    setChecklistB1(3); setChecklist(20)
                                }
                            }} checked={checklistB1 === 3} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A document issued by the Bailiwick of Jersey, the Bailiwick of Guernsey or the Isle of Man, which has been verified as valid by the Home Office Employer Checking Service, showing that the holder has been granted limited leave to enter or remain under Appendix EU to the Jersey Immigration Rules, Appendix EU to the Immigration (Bailiwick of Guernsey) Rules 2008 or Appendix EU to the Isle of Man Immigration Rules.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB2 !== 4) {
                                    setChecklistB1(4); setChecklist(20)
                                }
                            }} checked={checklistB1 === 4} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A document issued by the Bailiwick of Jersey or the Bailiwick of Guernsey, which has been verified as valid by the Home Office Employer Checking Service, showing that the holder has made an application for leave to enter or remain under Appendix EU to the Jersey Immigration Rules or Appendix EU to the Immigration (Bailiwick of Guernsey) Rules 2008, on or before 30 June 2021.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB2 !== 5) {
                                    setChecklistB1(5); setChecklist(20)
                                }
                            }} checked={checklistB1 === 5} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A frontier worker permit issued under regulation 8 of the Citizens' Rights (Frontier Workers) (EU Exit) Regulations 2020.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB2 !== 7) {
                                    setChecklistB1(7); setChecklist(20)
                                }
                            }} checked={checklistB1 === 7} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A <b>current</b> Immigration Status Document containing a photograph issued by the Home Office to the holder with a valid endorsement indicating that the named person may stay in the UK, and is allowed to do the type of work in question, together with an official document giving the person’s permanent National Insurance number and their name issued by a government agency or a previous employer.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB2 !== 6) {
                                    setChecklistB1(6); setChecklist(20)
                                }
                            }} checked={checklistB1 === 6} />
                        </Col>
                    </Row>
                </div>
                <div>
                    <h1>List B Group 2</h1>
                    <Row>
                        <Col md={10}>
                            <p>A document issued by the Home Office showing that the holder has made an application for leave to enter or remain under Appendix EU to the immigration rules (known as the EU Settlement Scheme) on or before 30 June 2021 <b>together with a Positive Verification Notice</b> from the Home Office Employer Checking Service.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 1" onChange={() => {
                                if (checklistB1 !== 1) {
                                    setChecklistB2(1); setChecklist(20)
                                }
                            }} checked={checklistB2 === 1} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A document issued by the Bailiwick of Jersey or the Bailiwick of Guernsey showing that the holder has made an application for leave to enter or remain under Appendix EU to the Jersey Immigration Rules or Appendix EU to the Immigration (Bailiwick of Guernsey) Rules 2008 on or before 30 June 2021 <b>together with a Positive Verification Notice</b> from the Home Office Employer Checking Service.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB1 !== 2) {
                                    setChecklistB2(2); setChecklist(20)
                                }
                            }} checked={checklistB2 === 2} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>An Application Registration Card issued by the Home Office stating that the holder is permitted to take the employment in question, <b>together with a Positive Verification Notice</b> from the Home Office Employer Checking Service</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB1 !== 3) {
                                    setChecklistB2(3); setChecklist(20)
                                }
                            }} checked={checklistB2 === 3} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <p>A Positive Verification Notice issued by the Home Office Employer Checking Service to the employer or prospective employer, which indicates that the named person may stay in the UK and is permitted to do the work in question.</p>
                        </Col>
                        <Col md={2}>
                            <Form.Check type='radio' type="radio" aria-label="List A 2" onChange={() => {
                                if (checklistB1 !== 4) {
                                    setChecklistB2(4); setChecklist(20)
                                }
                            }} checked={checklistB2 === 4} />
                        </Col>
                    </Row>
                </div>
                <h1>File Upload</h1>
                <h2>Files required:</h2>
                <ul>
                    {differentLegalName === 1 ? <li><h4>Differing legal name evidence</h4></li> : null}
                    {student === 1 ? <li><h4>Academic term and vacation timing evidence</h4></li> : null}
                    {((checklist > 0) && (checklist < 11)) === true ? <li><h4>List A Evidence</h4></li> : null}
                    {((checklistB1 > 0) || (checklistB2 > 0)) === true ? <li><h4>List B Group 1 & 2 Evidence</h4></li> : null}
                </ul>
                <Dropzone />
            </>
        )
    }
    return (
        <>
            <Row>
                <Col>
                    <h2>Right to Work Form</h2>
                </Col>
            </Row>
            <Container className="mt-4 mb-4">
                {validation ? <Alert variant={"warning"}>{validation}</Alert> : null}
            </Container>
            <Container>
                {formProgress === 0 ? FullLegalName() : null}
                {formProgress === 1 ? LegalNameDifferent() : null}
                {formProgress === 2 ? TimeLimitedPermission() : null}
                {formProgress === 3 ? AreYouAStudent() : null}
                {formProgress === 4 ? NIN() : null}
                {formProgress === 5 ? RightToWorkDocument() : null}
                <Row>
                    <Col className="mb-4 mt-4">
                        {formProgress === 0 ? null : <button type="button" className="jamescape-blue-button" onClick={() => setFormProgress(formProgress - 1)}>Back</button>}
                        {formProgress === 5 ? <button type="button" className="jamescape-blue-button float-right" onClick={() => Submit()}>{submitting ? <BeatLoader color={'#FFFFFF'}></BeatLoader> : "Submit Form"}</button> : <button type="button" className="jamescape-blue-button float-right" onClick={() => Validate()}>Next</button>}
                    </Col>
                </Row>
            </Container>
        </>
    )
}
