import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
export default function CloseToApplicants(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false); props.setState(false)}
    const handleShow = () => setShow(true);
    useEffect(() => {
        setShow(props.state)
    }, [props])
    function Close(props){
        axios.post('/jobs/api/v1/job/close.php', {
            id: props.id,
            toApplicants: true
        }).then((response) => {
            if(response.data.Message === 'OK'){
                handleClose();
            }
        })
    }
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Close Job To Applicants</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to close this job vaccancy to applicants?</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="jamescape-blue-button" onClick={handleClose}>Cancel</button>
                    <button type="button" className="jamescape-red-button" onClick={() => Close(props)}>Close to Applicants</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}