import React, { useState, useRef } from 'react';
import './style/css.css';
import "react-datetime/css/react-datetime.css";
import history from './utilities/History'
import {
  Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import { StaffLogin, ApplicantLogin, Protected } from './utilities/ProtectedRoute'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import AlertGenerator from './components/Alert';
import JobVacancies from './components/JobVacancies';
import JobPosting from './components/JobPosting';
import RenderNavbar from './components/RenderNavbar';
import AllJobs from './components/AllJobs';
import CreateJob from './components/CreateJob';
import EditJob from './components/EditJob';
import StaffOnly from './utilities/ProtectedRoute';
import VerifyToken from './utilities/VerifyToken';
import Apply from './components/Apply';
import MyApplications from './components/MyApplications';
import JobApplications from './components/JobApplications';
import RightToWorkForm from './components/RightToWorkForm';
import Workaround from './utilities/OnActiveWorkaround';
import ManageAlerts from './components/ManageAlerts';
import AcceptJob from './components/AcceptJob';
import ViewRightToWork from './components/ViewRightToWork';
import Footer from './components/Footer';
function App() {
  //const [user, setUser] = useState(0)
  const [error, setError] = useState(null);
  const [userStatus, setUserStatus] = useState(false)
  const topPageRef = useRef(null)

  const UpdateError = (error) => {
    setError(error)
  }
  return (
    <>
      <Router history={history}>
        <div className="page-wrapper">
          <div ref={topPageRef}></div>
          <RenderNavbar status={userStatus} setStatus={setUserStatus} />
          <Container>
            <Row>
              <Col>
                <Route path="/" render={(props) => <AlertGenerator {...props} error={error} updateError={UpdateError} />} />
              </Col>
            </Row>
            <Switch>
              <Route exact path="/">
                <Row>
                  <Col md={12} lg={12} xl={9} className="margin-fix">
                    <a href="https://jamescape.net"><button type="button" className="jamescape-blue-button mt-2 mb-2">Back to Jamescape</button></a>
                  </Col>
                </Row>
                <Row className="margin-top-10">
                  <Col md={12} lg={12} xl={9} className="margin-fix">
                    <div className="box jamescape-light-blue pl-5 pr-5 pt-3 pb-3">
                      <h1>We'll be in touch.</h1>
                      <p>Successful or unsuccessful, we always keep our applicants in the loop. You can check the status of your application at
                        any time by viewing your application on this website, but we’ll send you an email when we’ve got news for you.<br /><br />
                        Just to be extra sure you receive application updates from us, it’s probably worth adding messenger@barnor.co.uk
                        to your contacts of Safe Senders List to make sure our emails don’t end up in your spam folder.</p>
                    </div>
                  </Col>
                  <Col md={12} xl={3} className="margin-fix-right">
                    <div className="box jamescape-light-grey pt-3 pb-3 pl-4 pr-4">
                      <h1>Returning Applicants</h1>
                      <Link to="/applicant/applications"><button type="button" className="jamescape-blue-button mt-2 mb-2">View Applications</button></Link>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={8} md={12} lg={12} className="mt-4 p-0">
                    <h1>Current Vacancies</h1>
                    <JobVacancies updateError={UpdateError} />
                  </Col>
                </Row>
              </Route>
              <Route path="/applicant/login/token/:token" render={(props) => <VerifyToken {...props} updateError={UpdateError} />}>
              </Route>
              <Route exact path="/job"><Workaround /></Route>
              <Route path="/applicant/login">
                <ApplicantLogin updateError={UpdateError} />
              </Route>
              <Route path="/form">
              </Route>
              <Route path="/login">
                <StaffLogin />
              </Route>
              <Protected path="/job/:id/apply" render={(props) => <Apply {...props} updateError={UpdateError} />}>
              </Protected>
              <StaffOnly path="/alerts" render={(props) => <ManageAlerts {...props} updateError={UpdateError} />}>
              </StaffOnly>
              <Protected exact path="/right-to-work/" render={(props) => <RightToWorkForm {...props} updateError={UpdateError} />}>
              </Protected>
              <StaffOnly path="/right-to-work/:id" render={(props) => <ViewRightToWork {...props} updateError={UpdateError} />}>
              </StaffOnly>
              <Protected path="/applicant/applications" render={(props) => <MyApplications {...props} updateError={UpdateError} />}>
              </Protected>
              <Protected path="/job/:id/accept" render={(props) => <AcceptJob {...props} updateError={UpdateError} />}>
              </Protected>
              <StaffOnly path="/job/all" render={(props) => <AllJobs {...props} updateError={UpdateError} />}>
              </StaffOnly>
              <StaffOnly path="/job/create" render={(props) => <CreateJob {...props} updateError={UpdateError} />}>
              </StaffOnly>
              <StaffOnly path="/job/edit/:id" render={(props) => <EditJob {...props} updateError={UpdateError} />}>
              </StaffOnly>
              <StaffOnly path="/job/applications/:id" render={(props) => <JobApplications {...props} updateError={UpdateError} />}>
              </StaffOnly>
              <Route path="/job/:id" render={(props) => <JobPosting {...props} topPageRef={topPageRef} updateError={UpdateError} />}>
              </Route>
            </Switch>
          </Container>
        </div>
        <Footer status={userStatus} setStatus={setUserStatus} updateError={UpdateError} />
      </Router>
    </>
  );
}
export default App;
