import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BeatLoader from 'react-spinners/BeatLoader';
import Container from 'react-bootstrap/esm/Container';
export default function JobVacancies(props) {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (Cookies.get('Permission') === "Staff") {
            axios.get('/jobs/api/v1/job/get.php?all')
                .then((response) => {
                    if (response.data.Message === "OK") {
                        props.updateError(null)
                        setJobs(response.data.Jobs)
                        setLoading(false);
                    } else {
                        setJobs(null)
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
        } else {
            axios.get('/jobs/api/v1/job/get.php?open')
                .then((response) => {
                    if (response.data.Message === "OK") {
                        props.updateError(null)
                        setJobs(response.data.Jobs)
                        setLoading(false);
                    } else {
                        setJobs(null)
                    }
                }).catch(function (error) {
                    if (error.response) {
                        props.updateError(error.response.data)
                    } else if (error.request) {
                        props.updateError(error.request)
                    } else {
                        props.updateError(error.message)
                    }
                });
        }
    }, [])
    function RenderJobs() {
        if (jobs !== null) {
            if (loading) {
                return (
                    <Container>
                        <div className="d-flex justify-content-center p-5"><BeatLoader loading={loading} color={'#002F78'}></BeatLoader></div>
                    </Container>
                )
            } else {
                return (
                    <>
                        { jobs.map((job, i) =>
                            <div key={i} className="job-listing-grey">
                                <Row>
                                    <Col>
                                        <h2 className="blue-job-title">{job.title}</h2>
                                    </Col>
                                </Row>
                                {job.job_alert ? (
                                    <Row>
                                        <Col className="">
                                            <p className="jamescape-light-blue p-4">{job.job_alert}</p>
                                        </Col>
                                    </Row>
                                ) : (null)}
                                <h3 className="">Key Details</h3>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <p><b>Contract: </b>{job.contract}</p>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <p><b>Pay Rate: </b>{job.pay_rate}</p>
                                    </Col>
                                    <Col xs={12} sm={4} >
                                        <p><b>Reports to: </b>{job.reports_to}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <p><b>Location: </b>{job.location}</p>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <p><b>Seniority: </b>{job.seniority}</p>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <p><b>Department: </b>{job.department}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="top-bottom-padding">{job.description}</p>
                                    </Col>
                                </Row>
                                <Link to={"/job/" + job.id + "/apply"}><button type="button" className="jamescape-blue-button">Apply Now</button></Link>
                                <Link to={"/job/" + job.id}><button type="button" className="jamescape-blue-button ml-2">More Info</button></Link>
                            </div>
                        )}
                    </>
                )
            }
        } else {
            return null;
        }
    }
    return (
        <>
            <RenderJobs />
        </>
    )
}