import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import history from '../utilities/History';
import Nav from 'react-bootstrap/Nav';
import msLogin from '../resources/login-with-ms.svg'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
export default function Footer(props) {

    function LoggedOutNav() {
        return (
            <>
                <Container>
                    <Row>
                        <Col>
                            <p className="m-0">Admin Login</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Nav.Link onClick={RedirectWindow} className="mx-auto"><img src={msLogin} className="ml-2" alt="Sign in with Microsoft" /></Nav.Link>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    function AdminLoggedIn(){
        return (
            <>
                <Container>
                    <Row>
                        <Col>
                            <p className="m-0">Logged in as admin</p>
                        </Col>
                    </Row>
                </Container>
            </>
        )  
    }
    function RedirectWindow() {
        axios.get('/jobs/api/v1/auth/login.php?url=' + encodeURI(window.location.pathname).substring(5))
            .then((response) => {
                if (response.data.Message === 'Redirect') {
                    window.location.href = response.data.URL
                } else if (response.data.Message === 'OK') {
                    props.setStatus(true)
                    history.push(response.data.URL)
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
    }
    return (
        <>
            <footer className="footer vertical-center mt-4">
                <Container>
                    <Row>
                        <Col md={4}></Col>
                        <Col md={6} className="float-right footer-text">
                            {props.status === false ? <LoggedOutNav /> : null}
                            {props.status === "staff" ? <AdminLoggedIn /> : null}
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}