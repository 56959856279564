import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import BeatLoader from 'react-spinners/BeatLoader';
import axios from 'axios';
import history from '../utilities/History'
import Application from './Application';
export default function MyApplications(props) {
    const [applications, setApplications] = useState([])
    const [r2wStatus, setR2WStatus] = useState(null)
    const [message, setMessage] = useState(false)
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false)
    function Reload() {
        setReload(!reload);
    }
    useEffect(() => {
        axios.get('/jobs/api/v1/application/get.php?myApplications')
            .then((response) => {
                if (response.data.Message === "OK") {
                    props.updateError(null)
                    if (response.data.Applications !== undefined) {
                        setR2WStatus(response.data.r2w_status)
                        setApplications(response.data.Applications)
                    }
                    setLoading(false);
                } else {
                    setApplications([])
                    setMessage(response.data.Message)
                    setLoading(false);
                }
            }).catch(function (error) {
                if (error.response) {
                    props.updateError(error.response.data)
                } else if (error.request) {
                    props.updateError(error.request)
                } else {
                    props.updateError(error.message)
                }
            });
    }, [reload])
    return (
        <>
            <h1 className="text-center">My Applications</h1>
            <button type="button" className="jamescape-blue-button mb-4" onClick={history.goBack}>Back</button>
            <Container>
                {loading ? <div className="d-flex justify-content-center p-5"><BeatLoader loading={loading} color={'#002F78'}></BeatLoader></div> :
                    <>
                        <h1>{message}</h1>
                        {applications !== null ?
                            applications.map((application, i) =>
                                <div key={i} className="job-listing">
                                    <Application updateError={props.updateError} title={application.title} questions={application.Questions} applicant jobId={application.job_id} status={application.status} r2w_status={r2wStatus} reload={Reload} />
                                </div>
                            ) : null}
                    </>
                }

            </Container>
        </>
    )
}